import React, { Component } from "react";

import Modal from "./modal";
import PrivacyNotice from "./notice";
import {
  Footer,
  FooterWrapper,
  FlexBetween,
  Flex,
  Ul,
  Li,
  LinkStyle,
  PoweredBy,
  PoweredByText,
} from "./styles";
import SNA from "../../assets/svg/logo-dark.svg";
import design_lab_logo from "../../assets/svg/design-lab-logo.svg";
class AppFooter extends Component {
  state = {
    showModal: false,
  };

  showPrivacy = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <Footer>
        <FooterWrapper>
          <FlexBetween>
            <Flex>
              <Ul>
                <img
                  src={SNA}
                  alt="powered by SNA"
                  width={75}
                  height={31}
                  style={{ display: "block", marginRight: "40px" }}
                />
                <Li style={LinkStyle} onClick={this.showPrivacy}>
                  Privacy Policy
                </Li>
              </Ul>
            </Flex>
            <PoweredBy>
              <PoweredByText>A Project by </PoweredByText>
              <img
                src={design_lab_logo}
                alt="design lab logo"
                width={55}
                height={28}
              />
            </PoweredBy>
          </FlexBetween>

          <Modal
            closeModal={this.closeModal}
            isOpen={this.state.showModal}
            style={{ height: "500px" }}
          >
            <PrivacyNotice close={this.closeModal} />
          </Modal>
        </FooterWrapper>
      </Footer>
    );
  }
}

export default AppFooter;
