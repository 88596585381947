import React from 'react'
import { Route } from 'react-router-dom'
import DashboardNav from '../../../Components/DashboardNav'
import { Box, Flex, Stack, Text } from '@chakra-ui/core'
import { HeaderWrapper } from '../../../Components/DashboardHeader/styles'
import { Container, PageContent, MenuItem } from './styles'

function DashboardLayout({ children }) {
  return (
    <div>
      <DashboardNav />
      <div>{children}</div>
    </div>
  )
}

export const DashboardLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout>
          <Component {...matchProps} />
        </DashboardLayout>
      )}
    />
  )
}

// Different layout for settings page
function DashboardLayoutSettings({ children }) {
  return (
    <div>
      <DashboardNav />
      <HeaderWrapper>
        <Container>
          <Flex justifyContent="space-between" alignItems="center" width="100%" >
            <Flex direction="column">
              <Flex alignItems="center">
                <Text
                  fontWeight={500}
                  color="white"
                  fontSize={28}
                  fontFamily="Montserrat"
                >
                  Settings
                </Text>
              </Flex>
              <Text color="rgba(249, 249, 249, 0.7)" fontFamily="Montserrat">
                Create and view admins on this platform
              </Text>
            </Flex>
          </Flex>
        </Container>
      </HeaderWrapper>
      <Container>
        <PageContent>
          <Flex justifyContent="space-between">
            <Box width="25%">
              <Box
                background="white"
                paddingY={3}
                borderRadius={5}
                borderWidth={1}
              >
                <Stack>
                  <MenuItem
                    to="/Dashboard/settings/account"
                    activeClassName="active"
                  >
                    Account
                  </MenuItem>
                  <MenuItem
                    to="/Dashboard/settings/password"
                    activeClassName="active"
                  >
                    Password
                  </MenuItem>
                  <MenuItem
                    to="/Dashboard/settings/blocked-users"
                    activeClassName="active"
                  >
                    Blocked Users
                  </MenuItem>
                </Stack>
              </Box>
            </Box>
            <Box
              background="white"
              width="70%"
              borderRadius={5}
              borderWidth={1}
            >
              {children}
            </Box>
          </Flex>
        </PageContent>
      </Container>
    </div>
  )
}
// layput route for settings page
export const DashboardLayoutRouteSettings = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayoutSettings>
          <Component {...matchProps} />
        </DashboardLayoutSettings>
      )}
    />
  )
}
