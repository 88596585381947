import styled from "styled-components";

export const AuthBg = styled.main`
  background-image: url("./assets/Rectangle.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
`;

export const Flex = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: 529px) {
    display: block;
  }
`;
export const FlexItem = styled.div`
  width: 50%;
  @media (max-width: 529px) {
    width: 100%;
  }
`;

export const AuthContainer = styled.div`
  width: 80%;
  margin: 0 auto;
`;
export const AuthWrapper = styled.div`
  margin-top: 87.65px;
`;
export const SignUpTitle = styled.h3`
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: #333333;
  margin: 0;
  padding: 0;
`;

export const SubTitle = styled.p`
  font-family: Gotham;
  font-style: normal;
  font-weight: 100;
  font-size: 17px;
  line-height: 20px;
  color: #4e5457;
  margin-top: 5px;
`;

export const FormSection = styled.div``;
export const FormControl = styled.div`
  margin-bottom: 25px;
`;
export const FullFormControl = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  width: inherit;
  height: 70px;
`;
export const Label = styled.label`
  font-family: Gotham;
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 20px;
  color: #3c4859;
  margin: 10px 0 0 15px;
`;
export const Input = styled.input`
  height: inherit;
  border: none;
  outline: 0;
  box-shadow: 0;
  padding: 0;
  margin: 0 0 0 15px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 29px;
  position: relative;
  background: none;
`;

export const SignupButton = styled.button`
  width: 100%;
  height: 65px;
  background: #7e32ca;
  box-shadow: 0px 2px 6px rgba(196, 196, 196, 0.3);
  border-radius: 4px;
  border: none;
  outline: none;
  font-family: Gotham;
  font-weight: bold;
  font-size: 16px;
  line-height: 143.16%;
  color: #fefefe;
  cursor: pointer;
`;
export const Already = styled.p`
  font-family: Graphik;
  font-size: 16px;
  font-weight: 100;
  text-align: center;
  color: #5a6163;
  margin-bottom: 50px;
  margin-top: 31px;

  @media (max-width: 529px) {
    margin-top: 10px;
  }
`;

export const PasswordWraper = styled.div`
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const PasswordOrTextInput = styled.input`
  position: relative;
  height: inherit;
  border: none;
  outline: 0;
  box-shadow: 0;
  padding: 0;
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 29px;
  width: 100%;
  background: none;
`;

export const FlexItemWithBg = styled.div`
  width: 50%;
  @media (max-width: 529px) {
    display: none;
  }
`;

export const EyeSVG = styled.svg``;
