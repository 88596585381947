import styled from "styled-components";

export const Jumbotron = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 290px;
  position: relative;

  @media (max-width: 529px) {
    padding-bottom: 80px;
  }
`;
export const JumbotronContainer = styled.div`
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding-top: 154px;
  position: relative;

  @media (max-width: 529px) {
    width: 80%;
    text-align: center;
    padding-bottom: 0;
    padding-top: 80px;
  }
`;
export const Overview = styled.p`
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #5a6163;
  margin-bottom: 73px;
`;

export const FlexGrid = styled.div`
  display: flex;
  padding: 0;
  margin: 0;

  @media (max-width: 529px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
`;
export const FormInput = styled.input`
  box-sizing: border-box;
  width: 65%;
  height: 65px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 14px 0 70px;
  padding: 10px 24px;
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 29px;
  color: rgb(90, 97, 99);

  @media (max-width: 529px) {
    width: 100% !important;
    margin: 0;
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 29px;
    color: rgb(90, 97, 99);
  }
`;

export const SearchButton = styled.button`
  cursor: pointer !important;
  display: inline-block !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  border-color: black !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  border-width: 1px !important;
  border-style: solid !important;
  outline: none !important;
  padding: 14px 24px !important;
  -webkit-transition: box-shadow 0.2s ease, -webkit-transform 0.1s ease,
    transform 0.1s ease !important;
  -moz-transition: box-shadow 0.2s ease, transform 0.1s ease !important;
  transition: box-shadow 0.2s ease, -ms-transform 0.1s ease,
    -webkit-transform 0.1s ease, transform 0.1s ease !important;
  border: none !important;
  background: -webkit-linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  background: -moz-linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  background: linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  color: #ffffff !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 230px !important;
  height: 65px;
  border-radius: 4px;
  font-family: Gotham;
  font-size: 14px;
  color: #ffffff;
  margin-right: 26px;
  text-transform: uppercase;

  @media (max-width: 529px) {
    width: 100% !important;
    margin-top: 10px;
    text-align: center;
  }
`;
export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  position: relative;

  @media (max-width: 529px) {
    width: 100% !important;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  height: auto;

  @media (max-width: 529px) {
    height: 100vh;
    overflow-y: scroll;
  }
`;

export const AccountCreated = styled.p`
  margin-top: 10px;
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #0d0e1e;
`;
export const VerifyP = styled.p`
  font-size: 20px;
  color: #637381;
  margin-top: 60px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 29px;
  color: #637381;

  @media (max-width: 529px) {
    font-size: 13px;
    text-align: center;
  }
`;
export const ResendVerificationButton = styled.button`
  height: 45px;
  background: #7e32ca;
  box-shadow: 0px 2px 6px rgba(196, 196, 196, 0.3);
  border-radius: 4px;
  border: none;
  outline: none;
  font-family: Gotham;
  font-weight: bold;
  font-size: 16px;
  line-height: 143.16%;
  color: #fefefe;
  padding: 5px 8px;
  margin-top: 60px;
  margin-bottom: 60px;
`;

export const HeadlineModalStyle = {
  textAlign: "center",
  fontFamily: "Gotham",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "28px",
  color: "#333333",
  marginTop: "37px",
  marginBottom: "85px",
};
