import React, { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../../../utils/api";
import { useHistory } from "react-router-dom";
import {
  Button,
  Flex,
  Text,
  Badge,
  Input,
  InputGroup,
  InputRightElement,
  Avatar,
  Icon,
  Grid,
  Box,
  Tooltip,
  Heading,
  useToast,
} from "@chakra-ui/core";
import { HeaderWrapper } from "../../../Components/DashboardHeader/styles";
import { Container, PageContent } from "./styles";
import PlusIcon from "../../../assets/svg/plus-icon.svg";
import Store from "../../../utils/store";
import EmptyStateMessage from "../../../Components/Message";
import { search } from "../../../utils/requestCreator";
import Pagination from "../../../Components/Pagination/index";

let baseUrl = API_URL;

const UsersPage = () => {
  let history = useHistory();
  let toast = useToast();
  const [paginationData, setpaginationData] = useState({});

  const [admins, setAdmins] = useState([]);
  useEffect(() => {
    handleGetAdmins(1);
  }, []);

  const handleGetAdmins = (page) => {
    if (Store.getAuthToken()) {
      let userAuth = JSON.parse(Store.getAuthToken());

      axios
        .get(
          `${baseUrl}/admin/all/admins?page=${page}&limit=10&token=${userAuth.token.token}`
        )
        .then((response) => {
          let { data } = response;
          setAdmins(data.data);

          setpaginationData({
            total: data.total,
            perPage: data.perPage,
            page: data.page,
            lastPage: data.lastPage,
          });
        })
        .catch((error) => {
          // console.log(error.response)
        });
    }
  };

  const handleBlockAdmin = (id) => {
    if (Store.getAuthToken()) {
      let userAuth = JSON.parse(Store.getAuthToken());
      let { token } = userAuth.token;

      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .put(`${baseUrl}/admin/block/${id}?token=${token}`, config)
        .then((result) => {
          // console.log(result.data)
          toast({
            title: "Block Admin",
            description: "Admin successfully blocked",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
          handleGetAdmins();

          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
        })
        .catch((error) => {
          toast({
            title: "Block Admin",
            description: "Error blocking admin",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
        });
    }
  };

  const searchAdmin = async (query) => {
    if (Store.getAuthToken()) {
      let userAuth = JSON.parse(Store.getAuthToken());
      let { token } = userAuth.token;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      let result = await search(
        `${baseUrl}/admin/search/admins?query=${query}`,
        config
      );
      setAdmins(result || []);
    }
  };

  return (
    <div>
      <HeaderWrapper>
        <Container>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex direction="column">
              <Flex alignItems="center">
                <Text
                  fontFamily="Montserrat"
                  fontWeight={500}
                  color="white"
                  fontSize={28}
                >
                  All Admin
                </Text>
                <Badge
                  marginLeft={3}
                  backgroundColor="rgba(255, 255, 255, 0.15)"
                  color="white"
                  paddingY={1}
                  borderRadius={30}
                  paddingX={3}
                >
                  {paginationData.total}
                </Badge>
              </Flex>
              <Text fontFamily="Montserrat" color="rgba(249, 249, 249, 0.7)">
                Create and view admins
              </Text>
            </Flex>
            <Flex alignItems="center">
              <InputGroup
                borderColor="rgba(255, 255, 255, 0.5)"
                backgroundColor="rgba(45, 21, 59, 0.2)"
                width={250}
                display="flex"
                alignItems="center"
              >
                <Input
                  backgroundColor="rgba(45, 21, 59, 0.2)"
                  placeholder="Search for admins"
                  color="white"
                  borderWidth={1.5}
                  paddingY={6}
                  onChange={(e) => searchAdmin(e.target.value)}
                  paddingLeft="20px"
                  fontWeight={400}
                  fontFamily="Montserrat"
                />
                <InputRightElement
                  paddingY={6}
                  paddingRight="20px"
                  children={<Icon name="search" color="white" />}
                />
              </InputGroup>
              <Button
                onClick={() => history.push("/dashboard/admins/addAdmin")}
                marginLeft={5}
                color="#7E32CA"
                bg="white"
                size="lg"
              >
                <Text marginRight={2}>Add Admin</Text>
                <img src={PlusIcon} alt="" />
              </Button>
            </Flex>
          </Flex>
        </Container>
      </HeaderWrapper>

      <Container>
        <PageContent>
          {admins.length === 0 && (
            <EmptyStateMessage
              message="No Available Admin"
              illustration="DocumentIllustration"
            />
          )}
          {admins.length > 0 && (
            <Box marginBottom={10}>
              <Grid templateColumns="repeat(3, 1fr)" gap={8}>
                {admins.map((admin, index) => {
                  return (
                    <Flex
                      background="rgba(237, 237, 237, 0.3)"
                      width="100%"
                      height={200}
                      borderWidth={1}
                      flexDirection="column"
                      key={index}
                    >
                      <Flex p={5} height="60%">
                        <Avatar
                          background="rgba(130, 127, 152, 0.8)"
                          color="#fff"
                          fontSize="18px"
                          fontWeight={400}
                          size="md"
                          fontFamily="Montserrat"
                          name={admin.firstName + " " + admin.lastName}
                        />

                        <Box marginLeft={5}>
                          <Heading size="md" fontFamily="Montserrat">
                            {admin.firstName} {admin.lastName}
                          </Heading>
                          <Text fontFamily="Montserrat">
                            <Tooltip label={admin.email}>
                              {admin.email.substring(0, 20).concat("...")}
                            </Tooltip>
                          </Text>
                          <Text fontFamily="Montserrat" color="gray.500">
                            {" "}
                            {admin.institution}
                          </Text>
                        </Box>
                      </Flex>
                      <Flex
                        height="40%"
                        borderTopWidth={1}
                        p={5}
                        justifyContent="space-between"
                      >
                        <Button
                          borderColor="rgba(244, 44, 31, 0.8)"
                          borderWidth={1}
                          background="transparent"
                          color="rgba(244, 44, 31, 0.8)"
                          size="sm"
                          onClick={() => handleBlockAdmin(admin.id)}
                          fontFamily="Montserrat"
                        >
                          Block
                        </Button>
                        <Button
                          onClick={() =>
                            history.push(`/dashboard/admins/edit/${admin.id}`)
                          }
                          background="#7E32CA"
                          color="#fff"
                          size="sm"
                          fontFamily="Montserrat"
                        >
                          Edit
                        </Button>
                      </Flex>
                    </Flex>
                  );
                })}
              </Grid>
              <Pagination
                currentPage={paginationData.page}
                total={paginationData.total}
                lastPage={paginationData.lastPage}
                perPage={paginationData.perPage}
                handleOnChange={(e) => handleGetAdmins(e)}
              />
            </Box>
          )}
        </PageContent>
      </Container>
    </div>
  );
};

export default UsersPage;
