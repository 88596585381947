import styled from "styled-components";
import {css} from "@emotion/core";

export const Jumbotron = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 100px;
  position: relative;

  @media (max-width: 768px) {
    padding-bottom: 80px;
  }
`;
export const Filters = styled.div`
  height: max-content;
  margin-right: 30px;
  margin-top: 158px;
  @media (min-width: 520px) {
    
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  column-gap: 4px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  
  }
`;

export const Options = styled.div`
  border-radius: 8px;
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  margin-bottom: 14px;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const Pills = styled.div`
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
  
  @media (min-width: 768px) {
    margin-bottom: 0;
    flex-direction: row;
    font-size: 14px;
    justify-content: space-between;
  }
  
`;
export const Pill = styled.div`
  padding: 9px;
  width: max-content;
  border-radius: 8px;
  gap: 12px;
  display: flex;
  align-items: center;

  @media (max-width: 529px) {
  }
`;
export const Views = styled.div`
  position: relative;
  justify-content: end;
  background-color: rgba(255, 255, 255, 0.25);
  padding: 9px;
  width: max-content;
  border-radius: 8px;
  gap: 12px;
  display: none;
  align-items: center;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const Tab = styled.div`
  padding: 16px 32px;
  width: max-content;
  background-color: #FFFFFF;
  border-radius: 10px;
  margin-bottom: 0.1px;
  font-size: 16px;
  display: none;
  justify-content: center;
  margin-bottom: 12px;
  cursor: pointer;
  
  @media (min-width: 1024px) {
    display: flex;
  }
`;
export const Filter = styled.div`
  position: relative;
  padding: 8px 32px;
  background-color: #FFFFFF;
  border-radius: 8px;
  display: flex;
  align-items:center;
  cursor: pointer;
  margin-bottom:10px ;

  @media (min-width: 768px) {
    margin-bottom:0 ;
  }
`;
export const Main = styled.div`
  padding-top: 94px;
  display: flex;
  //width: 60%;
  min-height: 100vh;
  margin-left: 20px;
  @media (min-width: 1440px) {
    margin-left: 120px;
  }
`;

export const Overview = styled.p`
  font-family: Graphik;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  color: #616095;
  margin-top: 24px;
  text-align: center;
  margin-bottom: 16px;
  @media (min-width: 1024px) {
    text-align: left;  }
`;

export const SearchResultPage = styled.div`
  width: 100%;
  padding-right: 96px;
  @media (max-width: 768px) {
    padding-right: 20px;
  }
`;

export const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

export const override = css`
  margin: 0 50%;
`;

export const ModalContainer = styled.div`
  width: 100%;
`;

export const AccountCreated = styled.p`
  margin-top: 10px;
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #0d0e1e;
`;
export const VerifyP = styled.p`
  font-size: 20px;
  color: #637381;
  margin-top: 60px;

  @media (max-width: 529px) {
    font-size: 13px;
    text-align: center;
  }
`;
export const ResendVerificationButton = styled.button`
  height: 45px;
  background: #7e32ca;
  box-shadow: 0px 2px 6px rgba(196, 196, 196, 0.3);
  border-radius: 4px;
  border: none;
  outline: none;
  font-family: Gotham;
  font-weight: bold;
  font-size: 16px;
  line-height: 143.16%;
  color: #fefefe;
  padding: 5px 8px;
  margin-top: 60px;
  margin-bottom: 60px;
`;
