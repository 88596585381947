import React, { useEffect, useState } from "react";
import { Container, PageContent } from "./styles";
import {
  Flex,
  Box,
  Stack,
  Heading,
  Text,
  Avatar,
  Icon,
  Divider,
  Button,
  useToast,
} from "@chakra-ui/core";
import Twitter from "../../../assets/svg/twitter.svg";
import Clock from "../../../assets/svg/clock.svg";
import { HeaderWrapper } from "../../../Components/DashboardHeader/styles";
import Email from "../../../assets/svg/email.svg";
import Organisation from "../../../assets/svg/organisation.svg";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Store from "../../../utils/store";
import axios from "axios";
import API_URL from "../../../utils/api";
import EmptyStateMessage from "../../../Components/Message";
import Pagination from "../../../Components/Pagination/index";

let baseUrl = API_URL;

const User = ({ match }) => {
  const toast = useToast();
  let history = useHistory();
  let location = useLocation();
  const [paginationData, setpaginationData] = useState({});

  const [userData, setUserData] = useState({});
  const [searchHistory, setSearchHistory] = useState([]);

  useEffect(() => {
    if (Store.getAuthToken()) {
      let userAuth = JSON.parse(Store.getAuthToken());
      axios
        .get(
          `${baseUrl}/admin/view/${
            location.state.userData.id || location.state.userData.user_id
          }?token=${userAuth.token.token}`
        )
        .then((response) => {
          let { data } = response;
          setUserData(data);
        })
        .catch((err) => {
          console.log(err);
        });

      getHistory(1);
    }
  }, []);

  const getHistory = (page) => {
    let userAuth = JSON.parse(Store.getAuthToken());
    axios
      .get(
        `${baseUrl}/admin/history/${
          location.state.userData.id || location.state.userData.user_id
        }?page=${page}&limit=10&token=${userAuth.token.token}`
      )
      .then((response) => {
        let { data } = response;

        setSearchHistory(data.data);
        setpaginationData({
          total: data.total,
          perPage: data.perPage,
          page: data.page,
          lastPage: data.lastPage,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBlockUser = () => {
    let userAuth = JSON.parse(Store.getAuthToken());
    axios
      .put(
        `${baseUrl}/admin/block/${userData.id}?token=${userAuth.token.token}`
      )
      .then((response) => {
        let { data } = response;

        if (response.status === 200) {
          toast({
            title: "Success",
            description: data.message,
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
          const new_obj = { ...userData, is_active: !userData.is_active };
          setUserData(new_obj);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <HeaderWrapper>
        <Container>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex direction="row">
              <Button
                onClick={() => history.goBack()}
                background="rgba(249, 249, 249, 0.1)"
                borderWidth={1}
                borderColor="white"
                borderRadius={40}
                p={6}
                color="white"
                _hover=""
              >
                <Icon name="chevron-left" color="white" size="24px" />
                <Text fontFamily="Montserrat">Go Back</Text>
              </Button>
              <Divider orientation="vertical" marginX={5} />
              <div>
                <Heading color="white" size="lg" fontFamily="Montserrat">
                  {userData.firstName} {userData.lastName}
                </Heading>
                <Text color="rgba(249, 249, 249, 0.7)" fontFamily="Montserrat">
                  Joined {moment(userData.created_at).format("MMMM DD, YYYY")}
                </Text>
              </div>
            </Flex>
          </Flex>
        </Container>
      </HeaderWrapper>
      <Container>
        <PageContent>
          <Flex justifyContent="space-between" width="100%">
            <Stack spacing={5} width="28%">
              <Box
                textAlign="center"
                borderRadius={3}
                paddingX={25}
                paddingY={30}
                background="white"
                borderWidth="1px"
              >
                <div
                  style={{
                    border: "2px solid #6008A3",
                    display: "inline-block",
                    borderRadius: "100%",
                  }}
                >
                  <Avatar
                    background="rgba(126, 50, 202, 0.05)"
                    color="#6008A3"
                    fontSize="18px"
                    fontWeight={400}
                    size="md"
                    fontFamily="Montserrat"
                    name={userData.firstName + " " + userData.lastName}
                  />
                </div>
                <Heading fontSize="sm" marginTop={2} fontFamily="Montserrat">
                  {userData.firstName} {userData.lastName}
                </Heading>
                <Flex justifyContent="center" marginTop={2} alignItems="center">
                  <img src={Email} alt="" />
                  <Text fontFamily="Montserrat" marginLeft={2}>
                    {userData.email}
                  </Text>
                </Flex>
                <Flex marginTop={2} justifyContent="center" alignItems="center">
                  <img src={Organisation} alt="" />
                  <Text fontFamily="Montserrat" marginLeft={2}>
                    {userData.institution}
                  </Text>
                </Flex>
              </Box>
              <Box borderRadius={3} p={25} background="white" borderWidth="1px">
                <Box>
                  <Heading fontFamily="Montserrat" fontSize="sm">
                    How SNA’s data will be used by this user{" "}
                  </Heading>
                  <Text fontFamily="Montserrat" mt={4}>
                    {userData.description}
                  </Text>
                </Box>
              </Box>
            </Stack>
            <Box width="70%">
              <Box borderRadius={3} background="white" borderWidth="1px">
                <Flex
                  paddingX={25}
                  paddingY={25}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>
                    <Heading size="sm" display="flex">
                      <Text fontFamily="Montserrat">Search History</Text>
                      <Text color="gray.600">({paginationData.total})</Text>
                    </Heading>
                  </Box>

                  {userData.is_active ? (
                    <Button
                      borderColor="rgba(244, 44, 31, 0.8)"
                      borderWidth={1}
                      background="transparent"
                      color="rgba(244, 44, 31, 0.8)"
                      onClick={() => handleBlockUser()}
                      fontFamily="Montserrat"
                    >
                      Block User
                    </Button>
                  ) : (
                    <Text
                      fontFamily="Montserrat"
                      color="rgba(244, 44, 31, 0.8)"
                      fontWeight={600}
                    >
                      User Blocked
                    </Text>
                  )}
                </Flex>
                {searchHistory.length === 0 && (
                  <EmptyStateMessage
                    message="No search history"
                    illustration="DocumentIllustration"
                  />
                )}
                <Box>
                  <Flex
                    paddingX={25}
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    flexWrap="wrap"
                  >
                    {searchHistory.map((item, index) => {
                      return (
                        <Box
                          key={index}
                          p={5}
                          width="48.5%"
                          background="rgba(237, 237, 237, 0.1)"
                          borderWidth={1}
                          borderRadius={3}
                          marginBottom={5}
                        >
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Flex alignItems="center" marginRight={5}>
                              <img src={Twitter} alt="" />
                              <Text
                                fontFamily="Montserrat"
                                fontSize={14}
                                marginLeft={1}
                                fontWeight="600"
                              >
                                @{item.username}
                              </Text>
                            </Flex>
                            <Flex alignItems="center">
                              <img src={Clock} alt="" />
                              <Text
                                fontFamily="Montserrat"
                                fontSize={14}
                                marginLeft={1}
                                fontWeight="600"
                              >
                                {moment(item.created_at).format(
                                  "DD MMM, hh:mm a"
                                )}
                              </Text>
                            </Flex>
                          </Flex>
                          <Flex marginTop={2} flexDirection="column">
                            <Heading fontFamily="Montserrat" fontSize="sm">
                              Reason:{" "}
                            </Heading>
                            <Text fontFamily="Montserrat">{item.reason}</Text>
                          </Flex>
                        </Box>
                      );
                    })}
                  </Flex>
                </Box>
                <Box paddingX={5}>
                  <Pagination
                    currentPage={paginationData.page}
                    total={paginationData.total}
                    lastPage={paginationData.lastPage}
                    perPage={paginationData.perPage}
                    handleOnChange={(e) => getHistory(e)}
                  />
                </Box>
              </Box>
            </Box>
          </Flex>
        </PageContent>
      </Container>
    </div>
  );
};
export default User;
