import React, {useEffect} from "react";
import {createPortal} from "react-dom";

// 'modal-root' is a sibling to 'app-root'
const modalRoot = document.getElementById("modal-root");

function Modal({isOpen, closeModal, isSmall, children}) {
    // element to which the modal will be rendered
    // const el = document.createElement("div");
    // useEffect(() => {
    //     // append to root when the children of Modal are mounted
    //     modalRoot.appendChild(el);
    //     // do a cleanup
    //     // return () => {
    //     //     modalRoot.removeChild(el);
    //     // };
    // }, [isOpen]);

    return (
        isOpen &&
        createPortal(
            // child element
            <div
                onClick={closeModal}
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    height: "100vh",
                    width: "100%",
                    padding: "100px",
                    backgroundColor: "rgba(0,0,0,0.6)",
                    zIndex: "200"
                }}
            >
                <div className="popup_modal"
                     style={{
                         width: (isSmall ? "35%s" : "70%"),
                         height: "500px",
                         background: "white",
                         padding: "50px",
                         textAlign: "center",
                         margin: "0 auto",
                         borderRadius: "5.42251px",
                         position: "relative",
                         zIndex: "5",
                         overflowY: "scroll"
                     }}
                >
                    {children}
                </div>
            </div>,
            modalRoot
        )
    );
}

export default Modal;
