import React, { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/core";
import {
  DropDownWrapper,
  DropDownWrapperContent,
  DropDownMenu,
  DropDownMenuItem,
} from "./styles";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FilterByPeriod = ({ emitDateRange }) => {
  const [dropped, setdropped] = useState(false);
  const toggleDropdown = () => {
    setdropped(!dropped);
    setshowCalendar(false);
  };

  const options = [
    "All Time",
    "Last 7 days",
    "Last 28 days",
    "Last year",
    "Custom",
  ];
  const [selected, setselected] = useState(options[0]);
  const [showCalendar, setshowCalendar] = useState(false);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setstartDate(start);
    setendDate(end);

    let formattedStart = moment(start).format("YYYY-MM-DD");
    let formattedEnd = moment(end).format("YYYY-MM-DD");

    emitDateRange({ start: formattedStart, end: formattedEnd });
    // setdropped(false);
    setselected(
      moment(formattedStart).format("MMM DD") +
        "-" +
        moment(formattedEnd).format("MMM DD")
    );
  };

  const handleSelectOption = (option) => {
    let start = "";
    let end = "";
    if (option === "All Time") {
      setselected(option);
    } else if (option === "Last 7 days") {
      start = moment().subtract(7, "d").format("YYYY-MM-DD");
      end = moment().format("YYYY-MM-DD");
      setselected(
        moment(start).format("MMM DD") + "-" + moment(end).format("MMM DD")
      );
    } else if (option === "Last 28 days") {
      start = moment().subtract(28, "d").format("YYYY-MM-DD");
      end = moment().format("YYYY-MM-DD");
      setselected(
        moment(start).format("MMM DD") + "-" + moment(end).format("MMM DD")
      );
    } else if (option === "Last year") {
      start = moment()
        .subtract(1, "years")
        .startOf("year")
        .format("YYYY-MM-DD");
      end = moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD");
      setselected(
        moment(start).format("MMM DD") + "-" + moment(end).format("MMM DD")
      );
    } else if (option === "Custom") {
      // display calendar
      setshowCalendar(true);
      setdropped(false);
    }
    emitDateRange({ start, end });
    setdropped(false);
  };

  useEffect(() => {
    if (endDate) {
      setshowCalendar(false);
    }
  }, [endDate]);

  return (
    <Box>
      <DropDownWrapper>
        <DropDownWrapperContent onClick={() => toggleDropdown()}>
          Filter by: <Text marginLeft={2}>{selected}</Text>
        </DropDownWrapperContent>
        {dropped && (
          <DropDownMenu>
            {options.map((option, index) => (
              <DropDownMenuItem
                key={index}
                onClick={() => handleSelectOption(option)}
              >
                {option}
              </DropDownMenuItem>
            ))}
          </DropDownMenu>
        )}

        {showCalendar && (
          <Box
            position="absolute"
            top="50px"
            right={0}
            background="white"
            zIndex="1"
            borderRadius={8}
          >
            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              // on
            />
          </Box>
        )}
      </DropDownWrapper>
    </Box>
  );
};

export default FilterByPeriod;
