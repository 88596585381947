import React from 'react';


class ModalPlus extends React.Component{
  render(){
      const showHideClassname = this.props.show ? "modal display-block" : "modal display-none";
      const isPassword = this.props.isPassword ? " password-modal" : ""
      return(
          <div className={ showHideClassname + " " + isPassword}>
              <section className="modal-main">
                  {this.props.children}
                  {/* <img src={closeIcon} alt="" className="close-icon" onClick={this.props.handleClose}/> */}
              </section>
          </div>
      )
  }
}

export default ModalPlus;