let Store = {
  getAuthToken: () => {
    return localStorage.getItem("user");
  },
  getLoggedUserData: () => {
    return localStorage.getItem("userData");
  },
  setAuthToken: (name, data) => localStorage.setItem(name, data),
  setLoggedUserData: (name, data) => localStorage.setItem(name, data),
  clearAuth: () => localStorage.clear(),
};

export default Store;
