import React, { useState } from "react";
import {
  InnerNav,
  Nav,
  MenuItems,
  MenuItem,
  RightNavMenu,
  DropDownWrapper,
  DropDownWrapperContent,
  DropDownMenu,
  DropDownMenuItem,
} from "./styles";
import {Link, useHistory} from "react-router-dom";
import ArrowDropDown from "../../assets/svg/arrow-dropdown.svg";
import axios from "axios";
import API_URL from "../../utils/api";
import Store from "../../utils/store";
import { Box, Text } from "@chakra-ui/core";
import {Img} from "../Nav/styles";
import Logo from "../../assets/svg/logo-dark.svg";

let baseUrl = API_URL;

const DashboardNav = () => {
  const [dropped, setDropped] = useState(false);
  const [loggedUserData] = useState(JSON.parse(Store.getLoggedUserData()));
  let history = useHistory();
  let getToken = JSON.parse(Store.getAuthToken());
  let token = getToken.token.token;

  const toggleDropdown = () => {
    setDropped(!dropped);
  };

  const handleLogout = () => {
    axios
      .post(`${baseUrl}/logout`, {
        token: token,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          localStorage.clear();
          history.push("/");
        }
      });
  };

  return (
    <Nav>
      <InnerNav>
        <Box width="25%">
          <Link to='/'>
            <Img src={Logo} />
          </Link>

        </Box>
        <MenuItems>
          <MenuItem to="/Dashboard/request" activeClassName="active">
            Request
          </MenuItem>
          <MenuItem to="/Dashboard/users" activeClassName="active">
            Users
          </MenuItem>
          <MenuItem to="/Dashboard/admins" activeClassName="active">
            Admin
          </MenuItem>
          <MenuItem to="/Dashboard/settings/account" activeClassName="active">
            Settings
          </MenuItem>
          <MenuItem to="/Dashboard/overview" activeClassName="active">
            Overview
          </MenuItem>
        </MenuItems>
        <RightNavMenu>
          {/* <img src={BellIcon} alt="" /> */}
          <DropDownWrapper>
            <DropDownWrapperContent onClick={() => toggleDropdown()}>
              <Text color="rgba(8,27,56,0.8)" fontFamily="Montserrat">
                {loggedUserData &&
                  loggedUserData.firstName + " " + loggedUserData.lastName}
              </Text>
              <img style={{ marginLeft: "10px" }} src={ArrowDropDown} alt="" />
            </DropDownWrapperContent>
            {dropped && (
              <DropDownMenu>
                <DropDownMenuItem onClick={() => handleLogout()}>
                  Logout
                </DropDownMenuItem>
              </DropDownMenu>
            )}
          </DropDownWrapper>
        </RightNavMenu>
      </InnerNav>
    </Nav>
  );
};

export default DashboardNav;
