import styled from 'styled-components'

export const Mask = styled.div`
  background-image: url('/assets/images/login-mask.png');
  background-size: cover;
  height: 100%;
  /* background-color: linear-gradient(192.05deg, #7e32ca 0%, #6008a3 100%); */
`

export const Main = styled.div`
  width: 100vw;
  height: 100vh;
  background: #7e32ca;
  display: flex;
`
export const AuthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px auto;
  width: 480px;
`

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  margin-bottom: 32px;
`

export const Container = styled.div`
  width: 980px;
  margin: 0 auto;
`

export const Auth = styled.div`
  width: inherit;
  height: auto;
  background: #ffffff;
  border-radius: 8px;
  padding: 14px 46px;
`

export const ForgotPassword = styled.a`
  color: #7e32ca !important;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
`
