import styled from 'styled-components'

export const Jumbotron = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 290px;
  position: relative;

  @media (max-width: 529px) {
    padding-bottom: 101px;
  }
`

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  position: relative;

  @media (max-width: 529px) {
    width: 100% !important;
  }
`

export const JumbotronContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  padding-top: 154px;

  @media (max-width: 529px) {
    width: 80%;
    padding-top: 100px;
  }
`
export const Overview = styled.p`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.72;
  text-align: center;
  color: #000000;
  margin-bottom: 73px;
`
export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
`
export const Button = styled.button`
  cursor: pointer !important;
  display: inline-block !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  border-color: black !important;
  line-height: 20px !important;
  border-width: 1px !important;
  border-style: solid !important;
  outline: none !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  -webkit-transition: box-shadow 0.2s ease, -webkit-transform 0.1s ease,
    transform 0.1s ease !important;
  -moz-transition: box-shadow 0.2s ease, transform 0.1s ease !important;
  transition: box-shadow 0.2s ease, -ms-transform 0.1s ease,
    -webkit-transform 0.1s ease, transform 0.1s ease !important;
  border: none !important;
  background: -webkit-linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  background: -moz-linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  background: linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  color: #ffffff !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 230px !important;
  height: 50px;
  border-radius: 4px;
  font-family: Montserrat !important;
  font-size: 14px;
  font-weight: bold !important;
  color: #ffffff;
  margin-right: 26px;
  text-transform: uppercase;
`
export const LoginButton = styled.button`
  cursor: pointer !important;
  display: inline-block !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  border-width: 1px !important;
  border-style: solid !important;
  outline: none !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  width: 230px;
  height: 50px;
  background: #fff;
  border: 2px solid #7e32ca;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Montserrat !important;
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  text-transform: uppercase;
  color: #7e32ca;
`

export const Br = styled.br`
  @media (max-width: 529px) {
    display: none;
  }
`

export const ModalContainer = styled.div`
  width: 100%;
`

export const AccountCreated = styled.p`
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #0d0e1e;
`

export const VerifyP = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 29px;

  color: #637381;
  margin-top: 60px;

  @media (max-width: 529px) {
    font-size: 13px;
    text-align: center;
  }
`
export const ResendVerificationButton = styled.button`
  height: 45px;
  background: #7e32ca;
  box-shadow: 0px 2px 6px rgba(196, 196, 196, 0.3);
  border-radius: 4px;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 143.16%;
  color: #fefefe;
  padding: 10px 13px;
  margin-top: 60px;
  margin-bottom: 60px;
`
