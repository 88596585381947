import React, { useState, useEffect } from "react";
import axios from "axios";
import API_URL from "../../../utils/api";

import {
  Flex,
  Text,
  Badge,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
} from "@chakra-ui/core";
import { HeaderWrapper } from "../../../Components/DashboardHeader/styles";
import { Container, PageContent } from "./styles";

import Store from "../../../utils/store";
import UserTable from "./Table";
import EmptyStateMessage from "../../../Components/Message";
import { search } from "../../../utils/requestCreator";
import Pagination from "../../../Components/Pagination/index";

let baseUrl = API_URL;

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationData, setpaginationData] = useState({});

  useEffect(() => {
    handleGetUsers(1);
  }, []);

  const handleGetUsers = (page) => {
    if (Store.getAuthToken()) {
      let userAuth = JSON.parse(Store.getAuthToken());
      setLoading(true);
      axios
        .get(
          `${baseUrl}/admin/users?page=${page}&limit=10&token=${userAuth.token.token}`
        )
        .then((response) => {
          let { data } = response;
          setUsers(data.data);
          setLoading(false);
          // console.log(data);
          setpaginationData({
            total: data.total,
            perPage: data.perPage,
            page: data.page,
            lastPage: data.lastPage,
          });
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleSearch = async (query) => {
    if (Store.getAuthToken()) {
      let userAuth = JSON.parse(Store.getAuthToken());
      let { token } = userAuth.token;
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let result = await search(
        `${baseUrl}/admin/search/users?query=${query}`,
        config
      );
      setUsers(result || []);
    }
  };

  return (
    <div>
      <HeaderWrapper>
        <Container>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex direction="column">
              <Flex alignItems="center">
                <Text
                  fontFamily="Montserrat"
                  fontWeight={500}
                  color="white"
                  fontSize={28}
                >
                  All Users
                </Text>
                <Badge
                  marginLeft={3}
                  backgroundColor="rgba(255, 255, 255, 0.15)"
                  color="white"
                  paddingY={1}
                  borderRadius={30}
                  paddingX={3}
                >
                  {paginationData.total}
                </Badge>
              </Flex>
              <Text color="rgba(249,249,249,0.7)" fontFamily="Montserrat">
                See information for all registered users
              </Text>
            </Flex>
            <InputGroup
              width={269}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderColor="rgba(255,255,255,0.5)"
              background="rgba(45, 21, 59, 0.2)"
            >
              <Input
                onKeyUp={(e) => handleSearch(e.target.value)}
                placeholder="Search for users"
                color="rgba(255,255,255,1)"
                backgroundColor="rgba(45, 21, 59, 0.2)"
                borderWidth={1.5}
                paddingY={6}
                paddingLeft="20px"
                fontWeight={400}
                fontFamily="Montserrat"
              />
              <InputRightElement
                paddingRight="20px"
                paddingY={6}
                children={<Icon name="search" color="white" />}
              />
            </InputGroup>
          </Flex>
        </Container>
      </HeaderWrapper>

      <Container>
        <PageContent>
          {users.length > 0 && (
            <>
              <UserTable users={users} />
              <Pagination
                currentPage={paginationData.page}
                total={paginationData.total}
                lastPage={paginationData.lastPage}
                perPage={paginationData.perPage}
                handleOnChange={(e) => handleGetUsers(e)}
              />
            </>
          )}
          {users.length === 0 && (
            <EmptyStateMessage
              message="No Available User"
              illustration="UserIllustration"
              subMessage="Approve application requests to add users to this platform."
            />
          )}
        </PageContent>
      </Container>
    </div>
  );
};

export default UsersPage;
