import React, { useState, useEffect } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import Store from "../../utils/store";
import { Img } from "./styles";
import User from "../../assets/svg/user.svg"
import {
  Flex,
  Button,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList, Image,
} from "@chakra-ui/core";
import logo from "../../assets/svg/logo-dark.svg";

const Nav = () => {
  let history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState(JSON.parse(Store.getLoggedUserData()));

  useEffect(() => {
    if (Store.getAuthToken()) {
      setIsAuthenticated(true);
      // let userData = JSON.parse(Store.getLoggedUserData())
      // console.log(userData)
      // setProfile(userData)
      // isAuthenticated
    }
    // setPath(history.location.pathname)
  }, []);

  // state = {
  //   path: '',
  //   isAuthenticated: false,
  //   profile: undefined,
  //   redirect: '',
  //   showMenu: false,
  // }
  // componentDidMount() {
  //   if (Store.getAuthToken()) {
  //     this.setState({ isAuthenticated: true })
  //   }
  //   this.setState({ path: this.props.match.path })
  // }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.profile !== this.props.profile) {
  //     this.setState({ profile: nextProps.profile })
  //   }
  // }

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  // render() {
  // if (this.state.redirect) {
  //   return <Redirect to={this.state.redirect} />
  // }
  return (
    <Box shadow='md' paddingY='15px' background='white' position="fixed" width="100%" top={0} zIndex={100}>
      <Box maxW='1200px' width='95%' margin='0 auto'>
        <Flex justifyContent='space-between' alignItems='center'>
          <Link to='/'>
            <Img src={logo} />
          </Link>

          {!isAuthenticated && <Text fontFamily='Avenir LT Std'>About</Text>}
          {isAuthenticated && (
            <Menu>
              <MenuButton as={Button} borderRadius='33px' border='none' background='#F5F8FA' color='#0D0E1E'>
                <img src={User} alt='user profile' width={24} height={24}/>
                <Text marginLeft='10px' fontSize='14px' fontWeight='thin'>
                  {profile ? profile.firstName : ""}{" "}
                  {profile ? profile.lastName : ""}
                </Text>
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => history.push("/profile")}>
                  {" "}
                  Settings & Profile
                </MenuItem>
                <MenuItem onClick={() => logout()} color='#B17DE5'>
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Box>
    </Box>
  )
  // }
};

export default withRouter(Nav);
