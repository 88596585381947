import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const DropDownWrapper = styled.div`
  background: #f9f9f9;
  border: 1px solid rgba(149, 152, 170, 0.4);
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  background-color: white;
  height: 40px;
  top: 0;
  display: flex;
  align-items: center;
`;

export const DropDownMenu = styled.div`
  width: 100%;
  min-width: 143px;
  position: absolute;
  right: 0;
  top: 50px;
  background: #fff;
  z-index: 1;
  width: 200px;
  border-radius: 3px;
  border: 1px solid #dadada;
  padding: 10px 5px;
`;

export const DropDownMenuItem = styled.li`
  list-style: none;
  margin-bottom: 5px;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    color: #ccc;
  }

  &:last-child {
    background: rgba(126, 50, 202, 0.04);
    border-radius: 4px;
  }
`;

export const DropDownWrapperContent = styled.div`
  padding: 11px 14px;
  cursor: pointer;
  display: flex;
`;
