import styled from "styled-components";

export const Container = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  display: flex;
`;

export const PageContent = styled.div`
  margin-top: 50px;
  width: inherit;
`;
