import styled from "styled-components";

export const Button = styled.button`
  cursor: pointer !important;
  display: inline-block !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  border-color: black !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-width: 1px !important;
  border-style: solid !important;
  outline: none !important;
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  -webkit-transition: box-shadow 0.2s ease, -webkit-transform 0.1s ease,
    transform 0.1s ease !important;
  -moz-transition: box-shadow 0.2s ease, transform 0.1s ease !important;
  transition: box-shadow 0.2s ease, -ms-transform 0.1s ease,
    -webkit-transform 0.1s ease, transform 0.1s ease !important;
  border: none !important;
  background: -webkit-linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  background: -moz-linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  background: linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  color: #ffffff !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 230px !important;
  height: 65px;
  border-radius: 4px;
  font-family: Gotham;
  font-size: 14px;
  color: #ffffff;
  margin-right: 26px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.7px solid #7e32ca;
  margin-bottom: 65px;
`;

export const EyeSVG = styled.svg``;
