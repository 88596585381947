import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Jumbotron,
  Container,
  JumbotronContainer,
  Overview,
  FormWrapper,
  ModalContainer,
  AccountCreated,
  VerifyP,
  ResendVerificationButton,
} from "./styles";
import axios from "axios";
import { Helmet } from "react-helmet";
import Nav from "../../Components/Nav";
import AppFooter from "../../Components/Footer";
import API_URL from "../../utils/api";
import Store from "../../utils/store";
import Modal from "../../Components/Modal/Modal";
import ModalPlus from "../../Components/Modal/ModalPlus";
import { Text, Textarea, Stack, Input, Button } from "@chakra-ui/core";

let baseUrl = API_URL;

export default class SearchPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      twitter_handle: "",
      page: 1,
      isLoading: false,
      result: [],
      redirectTo: "",
      showModal: false,
      profile: {},
      timeout: 1000 * 60 * 10, //10 minutes timeout
      isTimedOut: false,
      showIntro: true,
      showReasonModal: false,
      reason: "",
    };
    this.idleTimer = null;
    this._onIdle = this._onIdle.bind(this);
    this._onAction = this._onAction.bind(this);
    this._onActive = this._onActive.bind(this);
  }

  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    this.setState({ isTimedOut: true });
    localStorage.clear();
    window.location.reload();
  }

  initialName = (handle) => {
    return handle.split("").slice(1).join("")[0].toUpperCase();
  };

  handleOnChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  loadMore() {
    this.setState(
      (prevState) => ({
        page: prevState.page + 1,
      }),
      this.getAllProblems
    );
  }

  loadInfo = (token) => {
    axios.get(`${baseUrl}/twitter/${this.state.twitter_handle}?token=${token}`);
  };

  searchTwitter = (evt) => {
    if (Store.getAuthToken() !== null) {
      if (!this.state.twitter_handle || !this.state.reason) {
        alert("Please complete the form");
        return;
      }
      let userData = JSON.parse(Store.getAuthToken());
      axios
        .get(
          `${baseUrl}/twitter/${this.state.twitter_handle}?reason=${this.state.reason}&&token=${userData.token.token}`
        )
        .then((response) => {
          // setTimeout(() => this.loadInfo(userData.token.token))
          console.log(response);
          this.setState({ showModal: true });
          // this.setState({ showReasonModal: false })
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  toggleReasonModal = () => {
    if (
      this.state.twitter_handle.length < 3 ||
      this.state.twitter_handle === ""
    ) {
      alert("Please enter a valid handle");
      return;
    }
    this.setState({ showReasonModal: true });
  };

  getUserProfile = () => {
    if (Store.getAuthToken()) {
      console.dir("Got token...");
      let userAuth = JSON.parse(Store.getAuthToken());
      axios
        .get(`${baseUrl}/profile?token=${userAuth.token.token}`)
        .then((result) => {
          let { data } = result;
          console.dir(data);
          this.setState({ profile: data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  componentDidMount() {
    let userData = JSON.parse(Store.getAuthToken());
    axios
      .get(`${baseUrl}/profile?token=${userData.token.token}`)
      .then((result) => {
        let { data } = result;
        this.setState({ profile: data });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    return (
      <main>
        {/* <IdleTimer
          ref={this.idleTimer}
          onActive={this._onActive}
          onIdle={this._onIdle}
          onAction={this._onAction}
          debounce={250}
          timeout={this.state.timeout}
        /> */}

        <Helmet>
          <title>SNA | Search</title>
        </Helmet>

        <Nav profile={this.state.profile} />

        <Jumbotron>
          <Container>
            <div className="cube-1">
              <img alt="" src="/assets/images/cube1.png" />
            </div>

            <div className="cube-3">
              <img alt="" src="/assets/images/cone1.png" />
            </div>

            <div className="cube-11">
              <img alt="" src="/assets/images/cube-11.png" />
            </div>

            <div className="cube-12">
              <img alt="" src="/assets/images/cube-12.png" />
            </div>

            <div className="cube-22">
              <img alt="" src="/assets/images/cube-22.png" />
            </div>

            <div className="cube-group-1">
              <img alt="" src="/assets/images/group1.png" />
            </div>
            <JumbotronContainer>
              <Overview>
                <Text fontSize="24px">
                  Welcome {this.state.profile.firstName}
                </Text>
                <Text fontSize="24px" marginTop="10px">
                  Enter a username below to get started
                </Text>
              </Overview>
              <FormWrapper>
                <Stack margin="0 auto" maxWidth="400px">
                  <Input
                    height="55px"
                    name="twitter_handle"
                    placeholder="Enter twitter username"
                    onChange={this.handleOnChange}
                    value={this.state.twitter_handle}
                  />
                  <Textarea
                    placeholder="Type reason here"
                    type="text"
                    height="100px"
                    value={this.state.reason}
                    onChange={(e) => this.setState({ reason: e.target.value })}
                  >
                    {this.state.reason}
                  </Textarea>
                  <Button
                    background="#7E32CA"
                    height="50px"
                    color="white"
                    onClick={this.searchTwitter}
                    isDisabled={!this.state.reason}
                  >
                    Search
                  </Button>
                </Stack>
              </FormWrapper>
            </JumbotronContainer>
          </Container>
        </Jumbotron>

        {/* <ModalAlt isOpen={this.state.showReasonModal} style={{ padding: 0 }}>
          <Heading
            fontSize="18px"
            padding={5}
            borderBottom="1px solid #EEEEEE"
            textAlign="center"
          >
            Search for {this.state.twitter_handle}
          </Heading>
          <Box
            maxW="500px"
            margin="0 auto"
            textAlign="center"
            width="100%"
            marginY={16}
          >
            <Text>Please let us know why you are running this search?</Text>
            <Box maxW="380px" margin="20px auto 0 auto">
              <Input
                background="rgba(246, 246, 246, 0.3)"
                height="55px"
                width="100%"
                placeholder="Type reason here"
                type="text"
                borderRadius="4px"
                borderColor="#DBDAE3"
                value={this.state.reason}
                onChange={(e) => this.setState({ reason: e.target.value })}
              />
            </Box>
          </Box>
          <Box
            padding={5}
            borderTop="1px solid #EEEEEE"
            textAlign="center"
            display="flex"
            justifyContent="center"
          >
            <Button
              onClick={this.searchTwitter}
              width="173px"
              color="#F2EFFF"
              background="#7E32CA"
              height="50px"
              borderRadius="4px"
            >
              CONTINUE
            </Button>
          </Box>
        </ModalAlt> */}

        <Modal isOpen={this.state.showModal}>
          <ModalContainer>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img alt="" width="132px" src="/assets/images/loader2.gif" />
            </div>

            <AccountCreated>
              Your search result is being processed!
            </AccountCreated>

            <VerifyP>
              You will receive an email notification once it’s complete.
            </VerifyP>

            <ResendVerificationButton
              onClick={(evt) => {
                this.setState({
                  twitter_handle: "",
                  reason: "",
                  showModal: false,
                });
              }}
            >
              Return to homepage
            </ResendVerificationButton>
          </ModalContainer>
        </Modal>

        {/* <ModalPlus show={this.state.showIntro}>
          <ModalContainer>
            <p className="into-header">Here’s how the Network Analysis works</p>

            <div className="modal-instruction__wrapper flex mobile-hidden">
              <div className="w-50 flex flex-column modal-item">
                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M39.1397 47.5H23.1996L18.2888 60H13L28.7143 20H33.625L49.3393 60H44.0504L39.1397 47.5ZM37.1754 42.5L31.1696 27.2125L25.1638 42.5H37.1754ZM63.0893 41.3375V40H68V60H63.0893V58.6625C61.5962 59.5393 59.9027 60.0006 58.1791 60C56.4554 59.9994 54.7623 59.537 53.2698 58.6591C51.7772 57.7813 50.5379 56.519 49.6762 54.999C48.8146 53.4791 48.361 51.755 48.361 50C48.361 48.245 48.8146 46.5209 49.6762 45.001C50.5379 43.481 51.7772 42.2187 53.2698 41.3409C54.7623 40.463 56.4554 40.0006 58.1791 40C59.9027 39.9994 61.5962 40.4607 63.0893 41.3375ZM58.1786 55C59.481 55 60.73 54.4732 61.651 53.5355C62.5719 52.5979 63.0893 51.3261 63.0893 50C63.0893 48.6739 62.5719 47.4021 61.651 46.4645C60.73 45.5268 59.481 45 58.1786 45C56.8762 45 55.6271 45.5268 54.7062 46.4645C53.7852 47.4021 53.2679 48.6739 53.2679 50C53.2679 51.3261 53.7852 52.5979 54.7062 53.5355C55.6271 54.4732 56.8762 55 58.1786 55Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Case Sensitive</h4>
                    <p>
                      Ensure the username is as it is on twitter eg “UserName”
                      is different from “username”. Ensure you have the correct
                      one.
                    </p>
                  </div>
                </div>

                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M59.0634 29H21.9831C19.8019 29 18 30.7453 18 32.858V47.142C18 49.3006 19.8019 51 21.9831 51H59.016C61.1972 51 62.9991 49.2547 62.9991 47.142V32.904C63.0465 30.7453 61.2446 29 59.0634 29ZM60.7705 47.142C60.7705 48.0605 60.0118 48.7954 59.0634 48.7954H21.9831C21.0347 48.7954 20.276 48.0605 20.276 47.142V32.904C20.276 31.9854 21.0347 31.2505 21.9831 31.2505H59.016C59.9644 31.2505 60.723 31.9854 60.723 32.904V47.142H60.7705Z"
                      fill="#7E32CA"
                    />
                    <path
                      d="M55.3175 42.0437C55.8865 41.171 56.2658 40.1606 56.2658 39.0583C56.2658 36.0729 53.7527 33.6387 50.6231 33.6387C47.541 33.6387 44.9805 36.0729 44.9805 39.0583C44.9805 42.0437 47.4936 44.5238 50.6231 44.5238C51.7612 44.5238 52.8043 44.2023 53.7053 43.6053L56.2658 46.0854C56.5029 46.3151 56.7874 46.4069 57.0719 46.4069C57.3564 46.4069 57.6409 46.3151 57.878 46.0854C58.3047 45.6721 58.3047 44.9372 57.878 44.5238L55.3175 42.0437ZM47.3039 39.0583C47.3039 37.2671 48.8213 35.8433 50.6231 35.8433C52.4724 35.8433 53.9424 37.313 53.9424 39.0583C53.9424 40.8495 52.425 42.2733 50.6231 42.2733C48.7739 42.3193 47.3039 40.8495 47.3039 39.0583Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Start a Search </h4>
                    <p>
                      Press the search button and allow our algorithm do the
                      work.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-50 flex flex-column modal-item">
                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M56 40C55.9996 36.4338 54.8078 32.9699 52.6138 30.1584C50.4198 27.3469 47.3495 25.349 43.8903 24.4819C40.431 23.6148 36.7813 23.9282 33.5206 25.3724C30.2598 26.8166 27.5751 29.3088 25.8926 32.4532C24.2101 35.5976 23.6263 39.214 24.234 42.728C24.8417 46.2421 26.6059 49.4525 29.2466 51.8493C31.8873 54.2461 35.2531 55.692 38.8094 55.9574C42.3658 56.2227 45.9088 55.2924 48.876 53.314L51.096 56.642C47.8124 58.8374 43.9499 60.0063 40 60C28.954 60 20 51.046 20 40C20 28.954 28.954 20 40 20C51.046 20 60 28.954 60 40V43C60.0002 44.4975 59.5202 45.9556 58.6304 47.1601C57.7407 48.3646 56.4881 49.2521 55.0567 49.692C53.6253 50.132 52.0905 50.1014 50.6778 49.6046C49.2651 49.1078 48.049 48.1711 47.208 46.932C45.8731 48.32 44.1659 49.293 42.2914 49.7342C40.4169 50.1754 38.4549 50.066 36.6411 49.4191C34.8273 48.7723 33.2389 47.6154 32.0666 46.0876C30.8944 44.5598 30.1882 42.726 30.0329 40.8066C29.8776 38.8872 30.2798 36.9637 31.1912 35.2673C32.1027 33.571 33.4845 32.1738 35.1707 31.2438C36.857 30.3138 38.7759 29.8904 40.6969 30.0245C42.6179 30.1587 44.4594 30.8446 46 32H50V43C50 43.7956 50.3161 44.5587 50.8787 45.1213C51.4413 45.6839 52.2043 46 53 46C53.7957 46 54.5587 45.6839 55.1213 45.1213C55.6839 44.5587 56 43.7956 56 43V40ZM40 34C38.4087 34 36.8826 34.6321 35.7574 35.7574C34.6321 36.8826 34 38.4087 34 40C34 41.5913 34.6321 43.1174 35.7574 44.2426C36.8826 45.3679 38.4087 46 40 46C41.5913 46 43.1174 45.3679 44.2426 44.2426C45.3679 43.1174 46 41.5913 46 40C46 38.4087 45.3679 36.8826 44.2426 35.7574C43.1174 34.6321 41.5913 34 40 34Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Drop the Sign</h4>
                    <p>
                      Do not include the “@” sign. eg if the username is
                      @username , just enter username.
                    </p>
                  </div>
                </div>

                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M45.6364 25.6364C45.3926 26.8362 45.3926 28.0729 45.6364 29.2727H24.5655L38.2927 41.3855L47.4727 33.1673C48.2473 34.1255 49.2145 34.9236 50.3145 35.5036L38.3127 46.2509L23.6364 33.3018V54.7273H52.7273V36.3636C53.9271 36.6074 55.1638 36.6074 56.3636 36.3636V56.5455C56.3636 57.0277 56.1721 57.4901 55.8311 57.8311C55.4901 58.1721 55.0277 58.3636 54.5455 58.3636H21.8182C21.336 58.3636 20.8735 58.1721 20.5325 57.8311C20.1916 57.4901 20 57.0277 20 56.5455V27.4545C20 26.9723 20.1916 26.5099 20.5325 26.1689C20.8735 25.8279 21.336 25.6364 21.8182 25.6364H45.6364ZM54.5455 32.9091C53.8292 32.9091 53.1199 32.768 52.4581 32.4939C51.7963 32.2198 51.195 31.818 50.6885 31.3115C50.182 30.805 49.7802 30.2037 49.5061 29.5419C49.232 28.8801 49.0909 28.1708 49.0909 27.4545C49.0909 26.7382 49.232 26.029 49.5061 25.3672C49.7802 24.7054 50.182 24.1041 50.6885 23.5976C51.195 23.0911 51.7963 22.6893 52.4581 22.4152C53.1199 22.1411 53.8292 22 54.5455 22C55.9921 22 57.3795 22.5747 58.4024 23.5976C59.4253 24.6205 60 26.0079 60 27.4545C60 28.9012 59.4253 30.2886 58.4024 31.3115C57.3795 32.3344 55.9921 32.9091 54.5455 32.9091Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Get Notified</h4>
                    <p>
                      Sit back and await a notification in your email letting
                      you know that your results are ready.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-instruction__wrapper flex flex-column desktop-hidden">
              <div className="w-50 w-100-m flex flex-column modal-item">
                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M39.1397 47.5H23.1996L18.2888 60H13L28.7143 20H33.625L49.3393 60H44.0504L39.1397 47.5ZM37.1754 42.5L31.1696 27.2125L25.1638 42.5H37.1754ZM63.0893 41.3375V40H68V60H63.0893V58.6625C61.5962 59.5393 59.9027 60.0006 58.1791 60C56.4554 59.9994 54.7623 59.537 53.2698 58.6591C51.7772 57.7813 50.5379 56.519 49.6762 54.999C48.8146 53.4791 48.361 51.755 48.361 50C48.361 48.245 48.8146 46.5209 49.6762 45.001C50.5379 43.481 51.7772 42.2187 53.2698 41.3409C54.7623 40.463 56.4554 40.0006 58.1791 40C59.9027 39.9994 61.5962 40.4607 63.0893 41.3375ZM58.1786 55C59.481 55 60.73 54.4732 61.651 53.5355C62.5719 52.5979 63.0893 51.3261 63.0893 50C63.0893 48.6739 62.5719 47.4021 61.651 46.4645C60.73 45.5268 59.481 45 58.1786 45C56.8762 45 55.6271 45.5268 54.7062 46.4645C53.7852 47.4021 53.2679 48.6739 53.2679 50C53.2679 51.3261 53.7852 52.5979 54.7062 53.5355C55.6271 54.4732 56.8762 55 58.1786 55Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Case Sensitive</h4>
                    <p>
                      Ensure the username is as it is on twitter eg “UserName”
                      is different from “username”. Ensure you have the correct
                      one.
                    </p>
                  </div>
                </div>

                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M59.0634 29H21.9831C19.8019 29 18 30.7453 18 32.858V47.142C18 49.3006 19.8019 51 21.9831 51H59.016C61.1972 51 62.9991 49.2547 62.9991 47.142V32.904C63.0465 30.7453 61.2446 29 59.0634 29ZM60.7705 47.142C60.7705 48.0605 60.0118 48.7954 59.0634 48.7954H21.9831C21.0347 48.7954 20.276 48.0605 20.276 47.142V32.904C20.276 31.9854 21.0347 31.2505 21.9831 31.2505H59.016C59.9644 31.2505 60.723 31.9854 60.723 32.904V47.142H60.7705Z"
                      fill="#7E32CA"
                    />
                    <path
                      d="M55.3175 42.0437C55.8865 41.171 56.2658 40.1606 56.2658 39.0583C56.2658 36.0729 53.7527 33.6387 50.6231 33.6387C47.541 33.6387 44.9805 36.0729 44.9805 39.0583C44.9805 42.0437 47.4936 44.5238 50.6231 44.5238C51.7612 44.5238 52.8043 44.2023 53.7053 43.6053L56.2658 46.0854C56.5029 46.3151 56.7874 46.4069 57.0719 46.4069C57.3564 46.4069 57.6409 46.3151 57.878 46.0854C58.3047 45.6721 58.3047 44.9372 57.878 44.5238L55.3175 42.0437ZM47.3039 39.0583C47.3039 37.2671 48.8213 35.8433 50.6231 35.8433C52.4724 35.8433 53.9424 37.313 53.9424 39.0583C53.9424 40.8495 52.425 42.2733 50.6231 42.2733C48.7739 42.3193 47.3039 40.8495 47.3039 39.0583Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Drop the Sign</h4>
                    <p>
                      Do not include the “@” sign. eg if the username is
                      @username , just enter username.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-50 flex flex-column modal-item">
                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M56 40C55.9996 36.4338 54.8078 32.9699 52.6138 30.1584C50.4198 27.3469 47.3495 25.349 43.8903 24.4819C40.431 23.6148 36.7813 23.9282 33.5206 25.3724C30.2598 26.8166 27.5751 29.3088 25.8926 32.4532C24.2101 35.5976 23.6263 39.214 24.234 42.728C24.8417 46.2421 26.6059 49.4525 29.2466 51.8493C31.8873 54.2461 35.2531 55.692 38.8094 55.9574C42.3658 56.2227 45.9088 55.2924 48.876 53.314L51.096 56.642C47.8124 58.8374 43.9499 60.0063 40 60C28.954 60 20 51.046 20 40C20 28.954 28.954 20 40 20C51.046 20 60 28.954 60 40V43C60.0002 44.4975 59.5202 45.9556 58.6304 47.1601C57.7407 48.3646 56.4881 49.2521 55.0567 49.692C53.6253 50.132 52.0905 50.1014 50.6778 49.6046C49.2651 49.1078 48.049 48.1711 47.208 46.932C45.8731 48.32 44.1659 49.293 42.2914 49.7342C40.4169 50.1754 38.4549 50.066 36.6411 49.4191C34.8273 48.7723 33.2389 47.6154 32.0666 46.0876C30.8944 44.5598 30.1882 42.726 30.0329 40.8066C29.8776 38.8872 30.2798 36.9637 31.1912 35.2673C32.1027 33.571 33.4845 32.1738 35.1707 31.2438C36.857 30.3138 38.7759 29.8904 40.6969 30.0245C42.6179 30.1587 44.4594 30.8446 46 32H50V43C50 43.7956 50.3161 44.5587 50.8787 45.1213C51.4413 45.6839 52.2043 46 53 46C53.7957 46 54.5587 45.6839 55.1213 45.1213C55.6839 44.5587 56 43.7956 56 43V40ZM40 34C38.4087 34 36.8826 34.6321 35.7574 35.7574C34.6321 36.8826 34 38.4087 34 40C34 41.5913 34.6321 43.1174 35.7574 44.2426C36.8826 45.3679 38.4087 46 40 46C41.5913 46 43.1174 45.3679 44.2426 44.2426C45.3679 43.1174 46 41.5913 46 40C46 38.4087 45.3679 36.8826 44.2426 35.7574C43.1174 34.6321 41.5913 34 40 34Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Start a Search </h4>
                    <p>
                      Press the search button and allow our algorithm do the
                      work.
                    </p>
                  </div>
                </div>

                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M45.6364 25.6364C45.3926 26.8362 45.3926 28.0729 45.6364 29.2727H24.5655L38.2927 41.3855L47.4727 33.1673C48.2473 34.1255 49.2145 34.9236 50.3145 35.5036L38.3127 46.2509L23.6364 33.3018V54.7273H52.7273V36.3636C53.9271 36.6074 55.1638 36.6074 56.3636 36.3636V56.5455C56.3636 57.0277 56.1721 57.4901 55.8311 57.8311C55.4901 58.1721 55.0277 58.3636 54.5455 58.3636H21.8182C21.336 58.3636 20.8735 58.1721 20.5325 57.8311C20.1916 57.4901 20 57.0277 20 56.5455V27.4545C20 26.9723 20.1916 26.5099 20.5325 26.1689C20.8735 25.8279 21.336 25.6364 21.8182 25.6364H45.6364ZM54.5455 32.9091C53.8292 32.9091 53.1199 32.768 52.4581 32.4939C51.7963 32.2198 51.195 31.818 50.6885 31.3115C50.182 30.805 49.7802 30.2037 49.5061 29.5419C49.232 28.8801 49.0909 28.1708 49.0909 27.4545C49.0909 26.7382 49.232 26.029 49.5061 25.3672C49.7802 24.7054 50.182 24.1041 50.6885 23.5976C51.195 23.0911 51.7963 22.6893 52.4581 22.4152C53.1199 22.1411 53.8292 22 54.5455 22C55.9921 22 57.3795 22.5747 58.4024 23.5976C59.4253 24.6205 60 26.0079 60 27.4545C60 28.9012 59.4253 30.2886 58.4024 31.3115C57.3795 32.3344 55.9921 32.9091 54.5455 32.9091Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Get Notified</h4>
                    <p>
                      Sit back and await a notification in your email letting
                      you know that your results are ready.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="close-btn__wrapper">
              <button
                onClick={() => this.setState({ showIntro: false })}
                className="btn-close-modal"
              >
                Close
              </button>
            </div>
          </ModalContainer>
        </ModalPlus> */}

        <ModalPlus show={true}>
          <ModalContainer>
            <p className="into-header">
              Server is currently down. We apologize for the inconvinience.
            </p>

            {/* <div className="modal-instruction__wrapper flex mobile-hidden">
              <div className="w-50 flex flex-column modal-item">
                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M39.1397 47.5H23.1996L18.2888 60H13L28.7143 20H33.625L49.3393 60H44.0504L39.1397 47.5ZM37.1754 42.5L31.1696 27.2125L25.1638 42.5H37.1754ZM63.0893 41.3375V40H68V60H63.0893V58.6625C61.5962 59.5393 59.9027 60.0006 58.1791 60C56.4554 59.9994 54.7623 59.537 53.2698 58.6591C51.7772 57.7813 50.5379 56.519 49.6762 54.999C48.8146 53.4791 48.361 51.755 48.361 50C48.361 48.245 48.8146 46.5209 49.6762 45.001C50.5379 43.481 51.7772 42.2187 53.2698 41.3409C54.7623 40.463 56.4554 40.0006 58.1791 40C59.9027 39.9994 61.5962 40.4607 63.0893 41.3375ZM58.1786 55C59.481 55 60.73 54.4732 61.651 53.5355C62.5719 52.5979 63.0893 51.3261 63.0893 50C63.0893 48.6739 62.5719 47.4021 61.651 46.4645C60.73 45.5268 59.481 45 58.1786 45C56.8762 45 55.6271 45.5268 54.7062 46.4645C53.7852 47.4021 53.2679 48.6739 53.2679 50C53.2679 51.3261 53.7852 52.5979 54.7062 53.5355C55.6271 54.4732 56.8762 55 58.1786 55Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Case Sensitive</h4>
                    <p>
                      Ensure the username is as it is on twitter eg “UserName”
                      is different from “username”. Ensure you have the correct
                      one.
                    </p>
                  </div>
                </div>

                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M59.0634 29H21.9831C19.8019 29 18 30.7453 18 32.858V47.142C18 49.3006 19.8019 51 21.9831 51H59.016C61.1972 51 62.9991 49.2547 62.9991 47.142V32.904C63.0465 30.7453 61.2446 29 59.0634 29ZM60.7705 47.142C60.7705 48.0605 60.0118 48.7954 59.0634 48.7954H21.9831C21.0347 48.7954 20.276 48.0605 20.276 47.142V32.904C20.276 31.9854 21.0347 31.2505 21.9831 31.2505H59.016C59.9644 31.2505 60.723 31.9854 60.723 32.904V47.142H60.7705Z"
                      fill="#7E32CA"
                    />
                    <path
                      d="M55.3175 42.0437C55.8865 41.171 56.2658 40.1606 56.2658 39.0583C56.2658 36.0729 53.7527 33.6387 50.6231 33.6387C47.541 33.6387 44.9805 36.0729 44.9805 39.0583C44.9805 42.0437 47.4936 44.5238 50.6231 44.5238C51.7612 44.5238 52.8043 44.2023 53.7053 43.6053L56.2658 46.0854C56.5029 46.3151 56.7874 46.4069 57.0719 46.4069C57.3564 46.4069 57.6409 46.3151 57.878 46.0854C58.3047 45.6721 58.3047 44.9372 57.878 44.5238L55.3175 42.0437ZM47.3039 39.0583C47.3039 37.2671 48.8213 35.8433 50.6231 35.8433C52.4724 35.8433 53.9424 37.313 53.9424 39.0583C53.9424 40.8495 52.425 42.2733 50.6231 42.2733C48.7739 42.3193 47.3039 40.8495 47.3039 39.0583Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Start a Search </h4>
                    <p>
                      Press the search button and allow our algorithm do the
                      work.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-50 flex flex-column modal-item">
                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M56 40C55.9996 36.4338 54.8078 32.9699 52.6138 30.1584C50.4198 27.3469 47.3495 25.349 43.8903 24.4819C40.431 23.6148 36.7813 23.9282 33.5206 25.3724C30.2598 26.8166 27.5751 29.3088 25.8926 32.4532C24.2101 35.5976 23.6263 39.214 24.234 42.728C24.8417 46.2421 26.6059 49.4525 29.2466 51.8493C31.8873 54.2461 35.2531 55.692 38.8094 55.9574C42.3658 56.2227 45.9088 55.2924 48.876 53.314L51.096 56.642C47.8124 58.8374 43.9499 60.0063 40 60C28.954 60 20 51.046 20 40C20 28.954 28.954 20 40 20C51.046 20 60 28.954 60 40V43C60.0002 44.4975 59.5202 45.9556 58.6304 47.1601C57.7407 48.3646 56.4881 49.2521 55.0567 49.692C53.6253 50.132 52.0905 50.1014 50.6778 49.6046C49.2651 49.1078 48.049 48.1711 47.208 46.932C45.8731 48.32 44.1659 49.293 42.2914 49.7342C40.4169 50.1754 38.4549 50.066 36.6411 49.4191C34.8273 48.7723 33.2389 47.6154 32.0666 46.0876C30.8944 44.5598 30.1882 42.726 30.0329 40.8066C29.8776 38.8872 30.2798 36.9637 31.1912 35.2673C32.1027 33.571 33.4845 32.1738 35.1707 31.2438C36.857 30.3138 38.7759 29.8904 40.6969 30.0245C42.6179 30.1587 44.4594 30.8446 46 32H50V43C50 43.7956 50.3161 44.5587 50.8787 45.1213C51.4413 45.6839 52.2043 46 53 46C53.7957 46 54.5587 45.6839 55.1213 45.1213C55.6839 44.5587 56 43.7956 56 43V40ZM40 34C38.4087 34 36.8826 34.6321 35.7574 35.7574C34.6321 36.8826 34 38.4087 34 40C34 41.5913 34.6321 43.1174 35.7574 44.2426C36.8826 45.3679 38.4087 46 40 46C41.5913 46 43.1174 45.3679 44.2426 44.2426C45.3679 43.1174 46 41.5913 46 40C46 38.4087 45.3679 36.8826 44.2426 35.7574C43.1174 34.6321 41.5913 34 40 34Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Drop the Sign</h4>
                    <p>
                      Do not include the “@” sign. eg if the username is
                      @username , just enter username.
                    </p>
                  </div>
                </div>

                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M45.6364 25.6364C45.3926 26.8362 45.3926 28.0729 45.6364 29.2727H24.5655L38.2927 41.3855L47.4727 33.1673C48.2473 34.1255 49.2145 34.9236 50.3145 35.5036L38.3127 46.2509L23.6364 33.3018V54.7273H52.7273V36.3636C53.9271 36.6074 55.1638 36.6074 56.3636 36.3636V56.5455C56.3636 57.0277 56.1721 57.4901 55.8311 57.8311C55.4901 58.1721 55.0277 58.3636 54.5455 58.3636H21.8182C21.336 58.3636 20.8735 58.1721 20.5325 57.8311C20.1916 57.4901 20 57.0277 20 56.5455V27.4545C20 26.9723 20.1916 26.5099 20.5325 26.1689C20.8735 25.8279 21.336 25.6364 21.8182 25.6364H45.6364ZM54.5455 32.9091C53.8292 32.9091 53.1199 32.768 52.4581 32.4939C51.7963 32.2198 51.195 31.818 50.6885 31.3115C50.182 30.805 49.7802 30.2037 49.5061 29.5419C49.232 28.8801 49.0909 28.1708 49.0909 27.4545C49.0909 26.7382 49.232 26.029 49.5061 25.3672C49.7802 24.7054 50.182 24.1041 50.6885 23.5976C51.195 23.0911 51.7963 22.6893 52.4581 22.4152C53.1199 22.1411 53.8292 22 54.5455 22C55.9921 22 57.3795 22.5747 58.4024 23.5976C59.4253 24.6205 60 26.0079 60 27.4545C60 28.9012 59.4253 30.2886 58.4024 31.3115C57.3795 32.3344 55.9921 32.9091 54.5455 32.9091Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Get Notified</h4>
                    <p>
                      Sit back and await a notification in your email letting
                      you know that your results are ready.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-instruction__wrapper flex flex-column desktop-hidden">
              <div className="w-50 w-100-m flex flex-column modal-item">
                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M39.1397 47.5H23.1996L18.2888 60H13L28.7143 20H33.625L49.3393 60H44.0504L39.1397 47.5ZM37.1754 42.5L31.1696 27.2125L25.1638 42.5H37.1754ZM63.0893 41.3375V40H68V60H63.0893V58.6625C61.5962 59.5393 59.9027 60.0006 58.1791 60C56.4554 59.9994 54.7623 59.537 53.2698 58.6591C51.7772 57.7813 50.5379 56.519 49.6762 54.999C48.8146 53.4791 48.361 51.755 48.361 50C48.361 48.245 48.8146 46.5209 49.6762 45.001C50.5379 43.481 51.7772 42.2187 53.2698 41.3409C54.7623 40.463 56.4554 40.0006 58.1791 40C59.9027 39.9994 61.5962 40.4607 63.0893 41.3375ZM58.1786 55C59.481 55 60.73 54.4732 61.651 53.5355C62.5719 52.5979 63.0893 51.3261 63.0893 50C63.0893 48.6739 62.5719 47.4021 61.651 46.4645C60.73 45.5268 59.481 45 58.1786 45C56.8762 45 55.6271 45.5268 54.7062 46.4645C53.7852 47.4021 53.2679 48.6739 53.2679 50C53.2679 51.3261 53.7852 52.5979 54.7062 53.5355C55.6271 54.4732 56.8762 55 58.1786 55Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Case Sensitive</h4>
                    <p>
                      Ensure the username is as it is on twitter eg “UserName”
                      is different from “username”. Ensure you have the correct
                      one.
                    </p>
                  </div>
                </div>

                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M59.0634 29H21.9831C19.8019 29 18 30.7453 18 32.858V47.142C18 49.3006 19.8019 51 21.9831 51H59.016C61.1972 51 62.9991 49.2547 62.9991 47.142V32.904C63.0465 30.7453 61.2446 29 59.0634 29ZM60.7705 47.142C60.7705 48.0605 60.0118 48.7954 59.0634 48.7954H21.9831C21.0347 48.7954 20.276 48.0605 20.276 47.142V32.904C20.276 31.9854 21.0347 31.2505 21.9831 31.2505H59.016C59.9644 31.2505 60.723 31.9854 60.723 32.904V47.142H60.7705Z"
                      fill="#7E32CA"
                    />
                    <path
                      d="M55.3175 42.0437C55.8865 41.171 56.2658 40.1606 56.2658 39.0583C56.2658 36.0729 53.7527 33.6387 50.6231 33.6387C47.541 33.6387 44.9805 36.0729 44.9805 39.0583C44.9805 42.0437 47.4936 44.5238 50.6231 44.5238C51.7612 44.5238 52.8043 44.2023 53.7053 43.6053L56.2658 46.0854C56.5029 46.3151 56.7874 46.4069 57.0719 46.4069C57.3564 46.4069 57.6409 46.3151 57.878 46.0854C58.3047 45.6721 58.3047 44.9372 57.878 44.5238L55.3175 42.0437ZM47.3039 39.0583C47.3039 37.2671 48.8213 35.8433 50.6231 35.8433C52.4724 35.8433 53.9424 37.313 53.9424 39.0583C53.9424 40.8495 52.425 42.2733 50.6231 42.2733C48.7739 42.3193 47.3039 40.8495 47.3039 39.0583Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Drop the Sign</h4>
                    <p>
                      Do not include the “@” sign. eg if the username is
                      @username , just enter username.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-50 flex flex-column modal-item">
                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M56 40C55.9996 36.4338 54.8078 32.9699 52.6138 30.1584C50.4198 27.3469 47.3495 25.349 43.8903 24.4819C40.431 23.6148 36.7813 23.9282 33.5206 25.3724C30.2598 26.8166 27.5751 29.3088 25.8926 32.4532C24.2101 35.5976 23.6263 39.214 24.234 42.728C24.8417 46.2421 26.6059 49.4525 29.2466 51.8493C31.8873 54.2461 35.2531 55.692 38.8094 55.9574C42.3658 56.2227 45.9088 55.2924 48.876 53.314L51.096 56.642C47.8124 58.8374 43.9499 60.0063 40 60C28.954 60 20 51.046 20 40C20 28.954 28.954 20 40 20C51.046 20 60 28.954 60 40V43C60.0002 44.4975 59.5202 45.9556 58.6304 47.1601C57.7407 48.3646 56.4881 49.2521 55.0567 49.692C53.6253 50.132 52.0905 50.1014 50.6778 49.6046C49.2651 49.1078 48.049 48.1711 47.208 46.932C45.8731 48.32 44.1659 49.293 42.2914 49.7342C40.4169 50.1754 38.4549 50.066 36.6411 49.4191C34.8273 48.7723 33.2389 47.6154 32.0666 46.0876C30.8944 44.5598 30.1882 42.726 30.0329 40.8066C29.8776 38.8872 30.2798 36.9637 31.1912 35.2673C32.1027 33.571 33.4845 32.1738 35.1707 31.2438C36.857 30.3138 38.7759 29.8904 40.6969 30.0245C42.6179 30.1587 44.4594 30.8446 46 32H50V43C50 43.7956 50.3161 44.5587 50.8787 45.1213C51.4413 45.6839 52.2043 46 53 46C53.7957 46 54.5587 45.6839 55.1213 45.1213C55.6839 44.5587 56 43.7956 56 43V40ZM40 34C38.4087 34 36.8826 34.6321 35.7574 35.7574C34.6321 36.8826 34 38.4087 34 40C34 41.5913 34.6321 43.1174 35.7574 44.2426C36.8826 45.3679 38.4087 46 40 46C41.5913 46 43.1174 45.3679 44.2426 44.2426C45.3679 43.1174 46 41.5913 46 40C46 38.4087 45.3679 36.8826 44.2426 35.7574C43.1174 34.6321 41.5913 34 40 34Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Start a Search </h4>
                    <p>
                      Press the search button and allow our algorithm do the
                      work.
                    </p>
                  </div>
                </div>

                <div className="flex align-center instruction-item">
                  <svg
                    width="80"
                    height="80"
                    viewBox="0 0 80 80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="80" height="80" rx="3.125" fill="#F9F9F9" />
                    <path
                      d="M45.6364 25.6364C45.3926 26.8362 45.3926 28.0729 45.6364 29.2727H24.5655L38.2927 41.3855L47.4727 33.1673C48.2473 34.1255 49.2145 34.9236 50.3145 35.5036L38.3127 46.2509L23.6364 33.3018V54.7273H52.7273V36.3636C53.9271 36.6074 55.1638 36.6074 56.3636 36.3636V56.5455C56.3636 57.0277 56.1721 57.4901 55.8311 57.8311C55.4901 58.1721 55.0277 58.3636 54.5455 58.3636H21.8182C21.336 58.3636 20.8735 58.1721 20.5325 57.8311C20.1916 57.4901 20 57.0277 20 56.5455V27.4545C20 26.9723 20.1916 26.5099 20.5325 26.1689C20.8735 25.8279 21.336 25.6364 21.8182 25.6364H45.6364ZM54.5455 32.9091C53.8292 32.9091 53.1199 32.768 52.4581 32.4939C51.7963 32.2198 51.195 31.818 50.6885 31.3115C50.182 30.805 49.7802 30.2037 49.5061 29.5419C49.232 28.8801 49.0909 28.1708 49.0909 27.4545C49.0909 26.7382 49.232 26.029 49.5061 25.3672C49.7802 24.7054 50.182 24.1041 50.6885 23.5976C51.195 23.0911 51.7963 22.6893 52.4581 22.4152C53.1199 22.1411 53.8292 22 54.5455 22C55.9921 22 57.3795 22.5747 58.4024 23.5976C59.4253 24.6205 60 26.0079 60 27.4545C60 28.9012 59.4253 30.2886 58.4024 31.3115C57.3795 32.3344 55.9921 32.9091 54.5455 32.9091Z"
                      fill="#7E32CA"
                    />
                  </svg>

                  <div className="content__wrapper">
                    <h4>Get Notified</h4>
                    <p>
                      Sit back and await a notification in your email letting
                      you know that your results are ready.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="close-btn__wrapper">
              <button
                onClick={() => this.setState({ showIntro: false })}
                className="btn-close-modal"
              >
                Close
              </button>
            </div> */}
          </ModalContainer>
        </ModalPlus>

        <AppFooter />
      </main>
    );
  }
}
