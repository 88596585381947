import styled from "styled-components";

export const FlexItem = styled.div`
  width: 50%;
  @media (max-width: 529px) {
    width: 100%;
  }
`;

export const FormSection = styled.div``;
export const FormControl = styled.div`
  margin-bottom: 25px;
  height: 65px;
`;

export const HalfFormControl = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  height: 65px;

  @media (max-width: 529px) {
    margin-bottom: 18px;
  }
`;

export const FullFormControl = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  height: 65px;
`;
export const Label = styled.label`
  margin: 10px 0 0 15px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 20px;
  color: #3c4859;
`;
export const Input = styled.input`
  height: inherit;
  border: none;
  outline: 0;
  box-shadow: 0;
  padding: 0;
  margin: 0 0 0 15px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 29px;
`;

export const GridTwo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 25px;

  @media (max-width: 529px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
`;

export const SignupButton = styled.button`
  width: 100%;
  height: 65px;
  background: #7e32ca;
  box-shadow: 0px 2px 6px rgba(196, 196, 196, 0.3);
  border-radius: 4px;
  border: none;
  outline: none;
  font-family: Gotham;
  font-weight: bold;
  font-size: 16px;
  line-height: 143.16%;
  color: #fefefe;
`;
