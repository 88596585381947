import styled from 'styled-components'

export const Img = styled.img``

export const Footer = styled.footer`
  height: 109px;
  background: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(196, 196, 196, 0.05);
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  @media (max-width: 529px) {
    width: 100%;
    height: auto;
    padding: 14px 20px;
    position: relative;
    bottom: calc(100% - 100px);
  }
`
export const FooterWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  line-height: 90px;

  @media (min-width: 1440px) {
    padding: 120px;
  }
`

export const Flex = styled.div`
  display: flex;
`

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Ul = styled.ul`
  list-style-type: none;
  height: inherit;
  display: flex;

  @media (max-width: 529px) {
    display: none;
  }
`

export const List = styled.ul`
  display: none;
  @media (max-width: 529px) {
    display: flex;
    justify-content: space-around;
  }
`

export const Li = styled.li`
  margin-right: 15px;
`

export const ListLi = styled.li`
  padding: 0;
  margin: 0;
`

export const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const PoweredByText = styled.span`
  margin-right: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 325;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #68686c;
`

export const LinkStyle = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '325',
  fontSize: '16px',
  lineHeight: '145%',
  textAlign: 'center',
  letterSpacing: '0.03em',
  color: '#68686C',
  textDecoration: 'none',
  marginLeft: "15px"
}
