import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";

import { Jumbotron } from "./styles";
import { Flex, Button, Text, Box } from "@chakra-ui/core";

import Nav from "../../Components/Nav";
import AppFooter from "../../Components/Footer";
import Store from "../../utils/store";
import API_URL from "../../utils/api";

const baseUrl = API_URL;

const Index = () => {
  let history = useHistory();

  const [profile, setProfile] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showModal, setShowModal] = useState(true);

  const handleOnIdle = (event) => {
    if (isAuthenticated) {
      localStorage.clear();
      window.location.reload();
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 100000 * 5 * 1,
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 250,
  });

  const toggleModal = (evt) => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (Store.getAuthToken()) {
      let userData = JSON.parse(Store.getAuthToken());
      axios
        .get(`${baseUrl}/profile?token=${userData.token.token}`)
        .then((result) => {
          let { data } = result;
          setProfile(data);
          setIsAuthenticated(true);
        })
        .catch((error) => {
          console.log(error.response);
        });
    } else {
      setProfile({});
      setIsAuthenticated(false);
    }
  }, [isAuthenticated]);

  return (
    <main>
      <Nav profile={profile} isAuthenticated={isAuthenticated} />

      <Jumbotron className={showModal ? "no-position" : ""}>
        <div className={"yellow-cube-up"}>
          <img alt="" src="/assets/images/cube-21.png" />
        </div>

        <div className={"yellow-cube-down"}>
          <img alt="" src="/assets/images/cube-22-big.png" />
        </div>

        <div className={"big-cone-right"}>
          <img alt="" src="/assets/images/cone-big.png" />
        </div>

        <div className={"cube-small-up"}>
          <img alt="" src="/assets/images/cube-32.png" />
        </div>

        <div className={"cube-small-down"}>
          <img alt="" src="/assets/images/cube-32.png" />
        </div>

        <Box
          style={{
            position: "absolute",
            width: "100%",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            placeItems: "center",
          }}
        >
          <Text
            maxWidth="720px"
            margin="0 auto"
            textAlign="center"
            fontSize="28px"
            // fontWeight="600"
            color="#111"
            fontFamily="Avenir LT Std"
          >
            Linked is a<span> social network analysis tool </span>
            designed to analyze social networks in real-time from social media.
          </Text>

          <Box marginTop="50px">
            {isAuthenticated ? (
              <Flex justifyContent="center">
                <Button
                  background="white"
                  borderWidth="1px"
                  borderColor="#7E32CA"
                  borderStyle="solid"
                  borderRadius="4px"
                  color="#7E32CA"
                  size="lg"
                  onClick={() => history.push("/dashboard/request")}
                >
                  Continue
                </Button>
              </Flex>
            ) : (
              <Flex justifyContent="center">
                <Button
                  width="225px"
                  background="white"
                  borderWidth="1px"
                  borderColor="#7E32CA"
                  borderStyle="solid"
                  borderRadius="4px"
                  color="#7E32CA"
                  size="lg"
                  marginX="10px"
                  _hover={false}
                  onClick={() => history.push("/auth/login")}
                >
                  Login
                </Button>
                <Button
                  width="225px"
                  background="#7E32CA"
                  borderRadius="4px"
                  color="white"
                  size="lg"
                  marginX="10px"
                  _hover={false}
                  onClick={() => history.push("/auth/register")}
                >
                  Create An Account
                </Button>
              </Flex>
            )}
          </Box>
        </Box>
      </Jumbotron>
      <AppFooter />
    </main>
  );
};

export default Index;
