import React from "react";
import { Box, Flex, Text, Image } from "@chakra-ui/core";
import MessageIllustration from "../../assets/svg/message-illustration.svg";
import UserIllustration from "../../assets/svg/user-illustration.svg";

const EmptyStateMessage = ({ message, subMessage, illustration }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="400px"
    >
      <Flex flexDirection="column" alignItems="center">
        {illustration === "DocumentIllustration" && (
          <Image src={MessageIllustration} alt="illustration" />
        )}

        {illustration === "UserIllustration" && (
          <Image src={UserIllustration} alt="illustration" />
        )}

        <Text
          fontFamily="Montserrat"
          fontSize="20px"
          color="#333333"
          marginTop={5}
          fontWeight="600"
        >
          {message}
        </Text>

        <Text
          fontFamily="Montserrat"
          fontSize="18px"
          color="#333333"
          marginTop={5}
          fontWeight="400"
          maxWidth="300px"
          textAlign="center"
        >
          {subMessage}
        </Text>
      </Flex>
    </Box>
  );
};

export default EmptyStateMessage;
