import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./index.css";
import "./App.scss";
import Home from "./Views/LandingPage";
import Signup from "./Views/Auth/SignUp";
import Login from "./Views/Auth/Login";
import SearchPage from "./Views/SearchPage";
import PrivateRoute from "./Views/routes";
import Store from "./utils/store";
import SearchResults from "./Views/SearchPage/Results";
import Profile from "./Views/Profile";
import AdminLogin from "./Views/Dashboard/Login";
import ForgotPassword from "./Views/Dashboard/ForgotPassword";
import ResetPassword from "./Views/Dashboard/ResetPassword";
import RequestPage from "./Views/Dashboard/RequestPage";
import UsersPage from "./Views/Dashboard/UsersPage";
import AdminsPage from "./Views/Dashboard/AdminsPage";
import AddAdminPage from "./Views/Dashboard/AddAdminPage";
import UserDetail from "./Views/Dashboard/User";
import Account from "./Views/Dashboard/Settings/account";
import Password from "./Views/Dashboard/Settings/password";
import BlockedUsers from "./Views/Dashboard/Settings/blocked-users";
import Overview from "./Views/Dashboard/Overview";

import { ThemeProvider, CSSReset } from "@chakra-ui/core";

// dashboard layout
import {
  DashboardLayoutRoute,
  DashboardLayoutRouteSettings,
} from "./Views/_layouts/dashboard";
import EditAdmin from "./Views/Dashboard/EditAdminProfile";

import "antd/dist/antd.min.css";

export default class App extends Component {
  state = {
    authed: false,
  };

  componentDidMount() {
    if (!Store.getAuthToken()) {
      this.setState({ authed: false });
    } else {
      this.setState({ authed: true });
    }
  }
  render() {
    return (
      <React.Fragment>
        <ThemeProvider>
          <CSSReset />
          <Router>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/auth/register' component={Signup} />
              <Route path='/auth/login' component={Login} />
              <Route
                exact
                path='/forgot-password'
                component={ForgotPassword}
              />
              <Route
                exact
                path='/reset-password'
                component={ResetPassword}
              />
              <PrivateRoute
                authed={this.state.authed}
                path='/search'
                component={SearchPage}
              />
              <PrivateRoute
                authed={this.state.authed}
                path='/results'
                component={SearchResults}
              />
              <PrivateRoute
                authed={this.state.authed}
                path='/profile'
                component={Profile}
              />
              <Route exact path='/admin/login' component={AdminLogin} />
              <Route
                exact
                path='/admin/forgot-password'
                component={ForgotPassword}
              />
              <Route
                exact
                path='/admin/reset-password'
                component={ResetPassword}
              />
              {/*  all dashboard routes */}
              <DashboardLayoutRoute
                exact
                path='/dashboard/request'
                component={RequestPage}
              />
              <DashboardLayoutRoute
                exact
                path='/dashboard/users'
                component={UsersPage}
              />
              <DashboardLayoutRoute
                exact
                path='/dashboard/users/:id'
                component={UserDetail}
              />
              <DashboardLayoutRoute
                exact
                path='/dashboard/admins'
                component={AdminsPage}
              />
              <DashboardLayoutRoute
                exact
                path='/dashboard/admins/addAdmin'
                component={AddAdminPage}
              />
              <DashboardLayoutRoute
                exact
                path='/dashboard/admins/edit/:id'
                component={EditAdmin}
              />

              <DashboardLayoutRouteSettings
                exact
                path='/dashboard/settings/account'
                component={Account}
              />

              <DashboardLayoutRouteSettings
                exact
                path='/dashboard/settings/password'
                component={Password}
              />
              <DashboardLayoutRouteSettings
                exact
                path='/dashboard/settings/blocked-users'
                component={BlockedUsers}
              />
              <DashboardLayoutRoute
                exact
                path='/dashboard/overview'
                component={Overview}
              />
            </Switch>
          </Router>
        </ThemeProvider>
      </React.Fragment>
    )
  }
}
