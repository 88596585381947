import React from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";

const UserTable = ({ users }) => {
  let history = useHistory();
  return (
    <div className="table dispatchTable">
      <table>
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th>Full Name</th>
            <th>Email Address</th>
            <th>Affiliate Institution</th>
            <th>Date Joined</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {users.map((item, index) => (
            <tr className="tr" key={index}>
              <td>
                {item.firstName} {item.lastName}
              </td>
              <td>{item.email}</td>
              <td>{item.institution}</td>
              <td>{moment(item.created_at).format("LL")}</td>
              <td>
                <button
                  onClick={() =>
                    history.push({
                      pathname: `/dashboard/users/${item.id}`,
                      state: { userData: item },
                    })
                  }
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
