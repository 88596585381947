import styled from 'styled-components'

export const NavBar = styled.nav`
  width: 100%;
  /* height: 60.7px; */
  line-height: 60.7px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
`
export const NavContainer = styled.div`
  width: 80%;
  margin: 0 auto;
`

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Img = styled.img`
  width: 80px;
  height: 19.51px;
`
export const Ul = styled.ul`
  list-style-type: none;
`

export const UL = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;

  color: #68686c;
`

export const Li = styled.li`
  text-transform: uppercase;
  cursor: pointer;
`

export const ProfileAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 189px;
  height: 40px;
  background: #f5f8fa;
  border-radius: 33px;
`

export const ProfileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProfileName = styled.p`
  margin: 0;
  padding: 0;
  margin-left: 5px;
  margin-right: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: rgb(90, 97, 99);
`
export const ProfileAvatarSection = styled.div`
  position: relative;
`

export const ProfileMenu = styled.div`
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  padding: 13px 11px;
  border-color: rgba(0, 0, 0, 0.2);
  color: #000;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  outline: none;
  overflow: hidden;
  display: none;
  right: 8px;
  top: 50px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-user-select: text;
  z-index: 100;
  ${({ active }) =>
    active &&
    `
    display: block;
  `}
`

export const LiLowerCase = styled.li`
  cursor: pointer;
`
