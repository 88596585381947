import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Nav = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 10px 0px 100px rgba(197, 197, 197, 0.25);
  padding-top: 14px;
  position: fixed;
  top: 0;
  z-index: 10;
`

export const InnerNav = styled.div`
  width: 95%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const MenuItems = styled.ul`
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  width: 40%;
  /* background: red; */
  justify-content: space-between;
`

export const MenuItem = styled(NavLink)`
  color: #626369;
  /* margin: 0 38px; */
  padding: 20px 0;
  border-bottom: 5px solid transparent;
  font-family: Montserrat;
  font-weight: 500;

  &:hover {
    text-decoration: none;
    color: #f8634b;
  }

  &.${(props) => props.activeClassName} {
    color: #f8634b;
    border-bottom: 5px solid #f8634b;
  }
`

export const RightNavMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 25%;
  font-family: Montserrat;
  color: #081b38;
`

export const DropDownWrapper = styled.div`
  background: #f9f9f9;
  border: 1px solid rgba(90, 97, 99, 0.07);
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 20px;
  position: relative;
  top: 0;
`

export const DropDownMenu = styled.div`
  width: 100%;
  min-width: 143px;
  position: absolute;
  left: 0;
  top: 50px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #dadada;
  padding: 15px 11px;
`

export const DropDownMenuItem = styled.li`
  color: #7e32ca;
  list-style: none;
  font-weight: 500;
`

export const DropDownWrapperContent = styled.div`
  padding: 11px 14px;
  cursor: pointer;
  display: flex;
`
