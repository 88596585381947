import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Text,
  Input,
  Icon,
  Box,
  Heading,
  Image,
  Divider,
  Select,
  useToast,
} from "@chakra-ui/core";
import API_URL from "../../../utils/api";
import Store from "../../../utils/store";
import axios from "axios";
import EmptyStateMessage from "../../../Components/Message";
import Pagination from "../../../Components/Pagination/index";

let baseUrl = API_URL;

const BlockedUsers = () => {
  const toast = useToast();
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [paginationData, setpaginationData] = useState({});

  useEffect(() => {
    handleGetBlockedUsers(1);
  }, []);

  const handleGetBlockedUsers = (page) => {
    if (Store.getAuthToken()) {
      let userAuth = JSON.parse(Store.getAuthToken());
      axios
        .get(
          `${baseUrl}/admin/blocked?page=${page}&limit=10&token=${userAuth.token.token}`
        )
        .then((response) => {
          let { data } = response;
          // get blocked users
          // console.log(data);
          let blockedUsers = data.data.filter((user) => {
            return !user.is_active;
          });
          setBlockedUsers(blockedUsers);
          setpaginationData({
            total: data.total,
            perPage: data.perPage,
            page: data.page,
            lastPage: data.lastPage,
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  };

  const handleUnblockUser = (id) => {
    let userAuth = JSON.parse(Store.getAuthToken());
    axios
      .put(`${baseUrl}/admin/unblock/${id}?token=${userAuth.token.token}`)
      .then((response) => {
        let { data } = response;
        // console.log(data);
        if (response.status === 200) {
          toast({
            title: "Success",
            description: data.message,
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
          handleGetBlockedUsers(1);
        }
      });
  };

  const renameRole = (role) => {
    if (role == "super_admin") return "Super Admin";
    if (role == "admin") return "Sub Admin";
    if (role == "user") return "User";
  };

  return (
    <Box>
      <Box paddingY={5} paddingX={10} borderBottomWidth={1} borderStyle="solid">
        <Heading fontSize={18} fontFamily="Montserrat">
          Blocked Users({paginationData.total || 0})
        </Heading>
      </Box>

      {blockedUsers.length > 0 ? (
        <Box className="table dispatchTable">
          <table>
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>Full Name</th>
                <th>Email Address</th>
                <th>User Type</th>
                <th></th>
              </tr>
            </thead>

            <tbody style={{ padding: "0 10px" }}>
              {blockedUsers.map((user, index) => (
                <tr className="tr" key={index}>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.email}</td>
                  <td>{user.roles.map((role) => renameRole(role.name))}</td>
                  <td>
                    <Button
                      onClick={() => handleUnblockUser(user.id)}
                      borderColor="#7E32CA"
                      color="#7E32CA"
                      background="transparent"
                      fontFamily="Montserrat"
                      fontWeight={400}
                    >
                      Unblock
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Box paddingX={5}>
            <Pagination
              currentPage={paginationData.page}
              total={paginationData.total}
              lastPage={paginationData.lastPage}
              perPage={paginationData.perPage}
              handleOnChange={(e) => handleGetBlockedUsers(e)}
            />
          </Box>
        </Box>
      ) : (
        <EmptyStateMessage
          message="No Blocked User(s)"
          illustration="UserIllustration"
          subMessage=""
        />
      )}
    </Box>
  );
};

export default BlockedUsers;
