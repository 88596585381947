import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import API_URL from "../../../utils/api";
import {
  Button,
  Flex,
  Text,
  Input,
  Icon,
  Box,
  Heading,
  Divider,
  Select,
  useToast,
} from "@chakra-ui/core";
import { HeaderWrapper } from "../../../Components/DashboardHeader/styles";
import { Container, PageContent } from "./styles";
import Store from "../../../utils/store";

let baseUrl = API_URL;

const EditAdmin = (props) => {
  let history = useHistory();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [adminType, setAdminType] = useState("");

  let {
    match: {
      params: { id },
    },
  } = props;

  useEffect(() => {
    getAdmin(id);
  }, []);

  const getAdmin = (id) => {
    if (Store.getAuthToken()) {
      let userAuth = JSON.parse(Store.getAuthToken());

      axios
        .get(`${baseUrl}/admin/view/${id}?token=${userAuth.token.token}`)
        .then(({ data }) => {
          let role = data.roles[0].name;
          // console.log(data);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setEmail(data.email);
          setAdminType(role);
        });
    }
  };

  const handleEditAdmin = () => {
    let {
      match: {
        params: { id },
      },
    } = props;

    if (!firstName || !lastName || !email || !adminType) {
      toast({
        title: "Complete the form",
        description: "Please complete the form",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    } else {
      setLoading(true);
      if (Store.getAuthToken()) {
        let userAuth = JSON.parse(Store.getAuthToken());
        axios
          .put(`${baseUrl}/admin/edit/${id}?token=${userAuth.token.token}`, {
            firstName,
            lastName,
            email,
            role: adminType,
          })
          .then((response) => {
            toast({
              title: "Edit Admin",
              description: "Admin edited successfully",
              status: "success",
              duration: 4000,
              isClosable: true,
              position: "top",
            });
            setFirstName("");
            setLastName("");
            setEmail("");
            setAdminType("");
            history.push("/dashboard/admins");
            setLoading(false);
          })
          .catch((error) => {
            toast({
              title: "Edit Admin",
              description: "Error updating admin",
              status: "error",
              duration: 4000,
              isClosable: true,
              position: "top",
            });
            setLoading(false);
          });
      }
    }
  };

  return (
    <div>
      <HeaderWrapper>
        <Container>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex direction="row">
              <Button
                onClick={() => history.goBack()}
                background="rgba(249, 249, 249, 0.1)"
                borderWidth={1}
                borderColor="white"
                borderRadius={40}
                p={6}
                color="white"
                _hover=""
              >
                <Icon name="chevron-left" color="white" size="24px" />
                Go Back
              </Button>
              <Divider orientation="vertical" marginX={5} />
              <div>
                <Heading
                  fontFamily="Montserrat"
                  color="white"
                  size="lg"
                  fontWeight="bold"
                >
                  Edit Admin
                </Heading>
                <Text fontFamily="Montserrat" color="rgba(249, 249, 249, 0.7)">
                  Create and view admins on this platform
                </Text>
              </div>
            </Flex>
          </Flex>
        </Container>
      </HeaderWrapper>

      <Container>
        <PageContent>
          <form>
            <Flex justifyContent="space-between" marginBottom={10}>
              <Box
                borderWidth={1.5}
                borderRadius={3}
                p={3}
                width="48%"
                borderColor="rgba(149, 152, 170, 0.4)"
              >
                <Text color="gray.500" fontFamily="Montserrat">
                  First Name
                </Text>
                <Input
                  variant="unstyled"
                  placeholder="First name"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  fontFamily="Montserrat"
                />
              </Box>
              <Box
                borderWidth={1.5}
                borderRadius={3}
                p={3}
                width="48%"
                borderColor="rgba(149, 152, 170, 0.4)"
              >
                <Text color="gray.500" fontFamily="Montserrat">
                  Last Name
                </Text>
                <Input
                  variant="unstyled"
                  placeholder="Last name"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
              </Box>
            </Flex>
            <Box
              borderWidth={1.5}
              borderRadius={3}
              p={3}
              marginBottom={10}
              borderColor="rgba(149, 152, 170, 0.4)"
            >
              <Text color="gray.500" fontFamily="Montserrat">
                Email Address
              </Text>
              <Input
                variant="unstyled"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Box>

            <Box
              borderWidth={1.5}
              borderRadius={3}
              p={3}
              marginBottom={10}
              borderColor="rgba(149, 152, 170, 0.4)"
            >
              <Text color="gray.500" fontFamily="Montserrat">
                Admin Type
              </Text>

              <Select
                variant="unstyled"
                onChange={(e) => setAdminType(e.target.value)}
                iconSize={8}
                placeholder="Select type"
                value={adminType}
              >
                <option value="admin">Admin only</option>
                <option value="super_admin">Super Admin</option>
              </Select>
            </Box>

            <Flex justifyContent="space-between" marginTop={10}>
              <Button
                borderColor="rgba(244, 44, 31, 0.8)"
                borderWidth={1}
                background="transparent"
                color="rgba(244, 44, 31, 0.8)"
                size="lg"
                onClick={() => history.goBack()}
                fontFamily="Montserrat"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleEditAdmin()}
                background="#7E32CA"
                color="#fff"
                size="lg"
                isLoading={loading}
                fontFamily="Montserrat"
              >
                Edit Admin
              </Button>
            </Flex>
          </form>
        </PageContent>
      </Container>
    </div>
  );
};

export default EditAdmin;
