import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import API_URL from "../../../utils/api";
import {
  Button,
  Flex,
  Text,
  Input,
  Box,
  Heading,
  Select,
  useToast,
} from "@chakra-ui/core";
import Store from "../../../utils/store";

let baseUrl = API_URL;

const Account = () => {
  const toast = useToast();

  let getUserData = JSON.parse(Store.getLoggedUserData());
  // console.log(getUserData);

  const [firstName, setFirstName] = useState(getUserData.firstName);
  const [lastName, setLastName] = useState(getUserData.lastName);
  const [email, setEmail] = useState(getUserData.email);
  const [adminType, setAdminType] = useState(getUserData.role);

  const [step, setStep] = useState("viewAccount");

  const cancel = () => {
    setFirstName(getUserData.firstName);
    setLastName(getUserData.lastName);
    setEmail(getUserData.email);
    setStep("viewAccount");
  };

  const handleUpdateProfile = () => {
    if (!firstName || !lastName || !email || !adminType) {
      toast({
        title: "Complete the form",
        description: "Please complete the form",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    } else {
      if (Store.getAuthToken()) {
        let userAuth = JSON.parse(Store.getAuthToken());
        axios
          .put(`${baseUrl}/profile?token=${userAuth.token.token}`, {
            firstName,
            lastName,
            email,
            role: adminType,
          })
          .then((response) => {
            Store.setLoggedUserData("userData", JSON.stringify(response.data));
            // console.log(response);
            if (response.status === 200) {
              toast({
                title: "Account Updated",
                description: "Account successfully updated",
                status: "success",
                duration: 4000,
                isClosable: true,
                position: "top",
              });
            }
          });
      }
    }
  };

  return (
    <Box>
      <Flex
        paddingY={5}
        paddingX={10}
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth={1}
        borderStyle="solid"
      >
        <Heading fontSize={18}>
          {step === "viewAccount" ? "Account" : "Edit Account"}
        </Heading>
        {step === "viewAccount" && (
          <Button
            onClick={() => setStep("editProfile")}
            background="#7E32CA"
            color="white"
            size="sm"
          >
            Edit Profile
          </Button>
        )}
      </Flex>
      <Flex padding={10} justifyContent="space-between">
        <Box width="48%">
          <Heading fontSize={18} color="rgba(59, 59, 59, 0.9)">
            Personal Information
          </Heading>
          <Text color="rgba(59, 59, 59, 0.8)">
            This is your account information
          </Text>
        </Box>
        <Box width="55%">
          <Flex justifyContent="space-between" marginBottom={5}>
            <Box
              borderWidth={1.5}
              borderRadius={3}
              p={3}
              width="48%"
              borderColor="rgba(149, 152, 170, 0.4)"
            >
              <Text color="rgba(51, 51, 51, 0.7)" fontFamily="Montserrat">
                First Name
              </Text>
              {step === "viewAccount" ? (
                <Text>{firstName}</Text>
              ) : (
                <Input
                  variant="unstyled"
                  value={firstName}
                  placeholder="First name"
                  onChange={(e) => setFirstName(e.target.value)}
                  fontFamily="Montserrat"
                  fontWeight={400}
                  color="rgba(51, 51, 51, 0.9)"
                />
              )}
            </Box>
            <Box
              borderWidth={1.5}
              borderRadius={3}
              p={3}
              width="48%"
              borderColor="rgba(149, 152, 170, 0.4)"
            >
              <Text color="rgba(51, 51, 51, 0.7)" fontFamily="Montserrat">
                Last Name
              </Text>
              {step === "viewAccount" ? (
                <Text>{lastName}</Text>
              ) : (
                <Input
                  variant="unstyled"
                  value={lastName}
                  placeholder="Last name"
                  onChange={(e) => setLastName(e.target.value)}
                  fontFamily="Montserrat"
                  fontWeight={400}
                  color="rgba(51, 51, 51, 0.9)"
                />
              )}
            </Box>
          </Flex>
          <Box
            borderWidth={1.5}
            borderRadius={3}
            p={3}
            marginBottom={5}
            borderColor="rgba(149, 152, 170, 0.4)"
          >
            <Text color="rgba(51, 51, 51, 0.7)" fontFamily="Montserrat">
              Email Address
            </Text>
            {step === "viewAccount" ? (
              <Text>{email}</Text>
            ) : (
              <Input
                variant="unstyled"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                fontFamily="Montserrat"
                fontWeight={400}
                color="rgba(51, 51, 51, 0.9)"
              />
            )}
          </Box>

          <Box
            borderWidth={1.5}
            borderRadius={3}
            p={3}
            marginBottom={5}
            borderColor="rgba(149, 152, 170, 0.4)"
          >
            <Text color="rgba(51, 51, 51, 0.7)" fontFamily="Montserrat">
              Admin Type
            </Text>
            {step === "viewAccount" ? (
              <Text>
                {adminType === "super_admin" ? "Super Admin" : "Admin Only"}
              </Text>
            ) : (
              <Box>
                <Select
                  variant="unstyled"
                  value={adminType}
                  onChange={(e) => setAdminType(e.target.value)}
                  iconSize={8}
                  defaultValue="hi"
                  fontFamily="Montserrat"
                  fontWeight={400}
                  color="rgba(51, 51, 51, 0.9)"
                >
                  <option value="admin">Admin only</option>
                  <option value="super_admin">Super Admin</option>
                </Select>
              </Box>
            )}
          </Box>
        </Box>
      </Flex>
      {step !== "viewAccount" && (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          paddingX={10}
          paddingBottom={10}
        >
          <Button
            background="transparent"
            borderRadius="4px"
            borderColor="#F42C1F"
            color="#F42C1F"
            fontWeight={500}
            borderWidth={1}
            width="153px"
            size="lg"
            fontFamily="Montserrat"
            onClick={() => cancel()}
          >
            Cancel
          </Button>
          <Button
            width="153px"
            color="white"
            size="lg"
            borderRadius="4px"
            background="#7E32CA"
            fontFamily="Montserrat"
            fontWeight={500}
            onClick={() => handleUpdateProfile()}
          >
            Save
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default Account;
