import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import queryString from "query-string";
import GridView from "../../../assets/svg/grid.svg"
import ListView from "../../../assets/svg/list.svg"
import Copy from "../../../assets/svg/copy.svg"
import {
    Pill,
    FilterGroup,
    Pills,
    Options,
    Views,
    Filter,
    Tab,
    Main,
    Filters,
    Container,
    Overview,
    override,
    SearchResultPage,
    ModalContainer,
    AccountCreated,
    VerifyP,
    ResendVerificationButton,
} from "./styles";
import axios from "axios";

import SyncLoader from "react-spinners/ClipLoader";
import IdleTimer from "react-idle-timer";
import {Helmet} from "react-helmet";
import _ from "lodash";

import Nav from "../../../Components/Nav";
import AppFooter from "../../../Components/Footer";
import API_URL from "../../../utils/api";
import Store from "../../../utils/store";
import Modal from "../../../Components/Modal/Modal";
import styled from "styled-components";
import {Divider} from "@chakra-ui/core";

let baseUrl = API_URL;

export default class SearchResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'top contacts',
            interactions: "in-degree",
            inDegree: 0,
            outDegree: 0,
            view: "list",
            filterBy1stDeg: false,
            filterBy2ndDeg: false,
            filterBy3rdDeg: false,
            twitter_handle: "",
            page: 1,
            isLoading: false,
            result: [],
            filteredData: [],
            currentView: [],
            filterBy: "",
            totalContacts: 0,
            redirectTo: "",
            showModal: false,
            searchTerm: "",
            profile: {},
            timeout: 1000 * 60 * 10,
            isTimedOut: false,
            currentPage: 1,
            itemsPerPage: 10,
        };
        this.containerRef = React.createRef();
        this.idleTimer = null;
        this._onIdle = this._onIdle.bind(this);
        this._onAction = this._onAction.bind(this);
        this._onActive = this._onActive.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleSort = this.handleFilter.bind(this);
        this.copyHandle = this.copyHandle.bind(this);
        this.loadMore = this.loadMore.bind(this);
    }

    _onAction(e) {
        this.setState({isTimedOut: false});
    }

    _onActive(e) {
        this.setState({isTimedOut: false});
    }

    _onIdle(e) {
        this.setState({isTimedOut: true});
        localStorage.clear();
        window.location.reload();
    }

    copyHandle = (handle) => {
        navigator.clipboard.writeText(handle).catch(err => console.error(err))
    }
    initialName = (handle) => {
        handle = this.removeEmojis(handle);
        handle = handle.replace(/'/g, "");
        let splitName = handle.split(" ");
        if (splitName.length > 1) {
            let firstLetter = this.removeEmojis(splitName[0].split("")[0] || "");
            let lastLetter = this.removeEmojis(splitName[1].split("")[0] || "");

            if (lastLetter === "'" || lastLetter === '"') {
                return `${firstLetter}`;
            }

            return `${firstLetter}${lastLetter}`;
        }

        return this.removeEmojis(splitName[0].split("")[0] || "");
    };

    removeEmojis = (string) => {
        var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
        return string.replace(regex, "");
    };

    handleFilter = (filterBy) => {
        if (filterBy === 'all') {
            this.setState((prevState) => ({
                filteredData: this.state.finalData,
                currentView: this.state.finalData.slice(0, prevState.itemsPerPage),
                currentPage: 1
            }))
            return
        }
        if (Object.keys(this.state.result).includes(filterBy)) {
            this.setState((prevState) => ({
                filteredData: this.state.result[filterBy],
                currentView: this.state.result[filterBy].slice(0, prevState.itemsPerPage),
                currentPage: 1
            }))
        } else {
            this.setState({
                filteredData: []
            })
        }
    };
    handleSort = (filterBy) => {
        if (Object.keys(this.state.result).includes(filterBy)) {
            this.setState({
                filteredData: this.state.result[filterBy]
            })
        }
    }

    loadMore() {
        this.setState((prevState) => {
            return {
                currentPage: prevState.currentPage + 1,
                currentView: this.state.filteredData.slice(0, this.state.itemsPerPage * (prevState.currentPage + 1))
            };
        })
    }

    loadInfo = (token) => {
        axios.get(`${baseUrl}/twitter/${this.state.twitter_handle}?token=${token}`);
    };

    searchTwitter = (evt) => {
        if (Store.getAuthToken() !== null) {
            let userData = JSON.parse(Store.getAuthToken());
            if (this.state.twitter_handle.length < 3) {
                alert("Please enter a valid handle");
                return;
            }

            this.setState({showModal: true});
            setTimeout(() => this.loadInfo(userData.token.token));
        }
    };


    componentDidMount() {
        let userData = JSON.parse(Store.getAuthToken());
        axios
            .get(`${baseUrl}/profile?token=${userData.token.token}`)
            .then((result) => {
                let {data} = result;
                this.setState({profile: data});
            });

        let params = queryString.parse(this.props.location.search);
        if (!_.isEmpty(params) && params.handle) {
            this.setState({isLoading: true});
            axios
                .get(`${baseUrl}/twitter/data/results?username=${params.handle}&token=${userData.token.token}`)
                .then((result) => {
                    let {data} = result;
                    if (data.in_out_degree) {
                        let inDegree = 0;
                        let outDegree = 0;
                        const keys = Object.keys(data.in_out_degree);
                        let finalData = [];

                        for (const key of keys) {
                            const indegree = data.in_out_degree[key].reduce((a, b) => {
                                return a + b.in
                            }, 0)
                            const outdegree = data.in_out_degree[key].reduce((a, b) => {
                                return a + b.out
                            }, 0)
                            inDegree += indegree
                            outDegree += outdegree
                            finalData = [...finalData, ...data.in_out_degree[key]];
                        }
                        this.setState({
                            finalData: finalData,
                            inDegree: inDegree,
                            outDegree: outDegree,
                            filteredData: finalData,
                            searchTerm: params.handle,
                            result: data.in_out_degree,
                            currentView: finalData.slice(0, this.state.itemsPerPage),
                            isLoading: false,
                            totalContacts: finalData.length,
                        });
                    }
                })
                .catch((error) => {
                    this.setState({isLoading: false});
                    console.dir("An Error occurred.");
                });
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo}/>;
        }

        return (<main className="results-container">
            <IdleTimer
                ref={this.idleTimer}
                onActive={this._onActive}
                onIdle={this._onIdle}
                onAction={this._onAction}
                debounce={250}
                timeout={this.state.timeout}
            />
            <Helmet>
                <title>SNA | Search</title>
            </Helmet>
            <Nav profile={this.state.profile}/>
            <Container>
                <Main>
                    <Filters>
                        <Tab onClick={() => {
                            this.containerRef.current?.firstElementChild?.scrollIntoView({behavior: 'smooth'});
                            this.setState((prevState) => ({
                                activeTab: 'top contacts',
                                interactions: '',
                                currentPage: 1,
                                currentView: prevState.finalData.slice(0, prevState.itemsPerPage)
                            }))
                        }} style={{
                            border: this.state.activeTab === 'top contacts' ? '1px solid #7E32CA' : '',
                            boxShadow: this.state.activeTab === 'top contacts' ? '0px 0px 0px 4px #7E32CA1A' : ''
                        }}><p
                            className="tab-title">Top Contacts</p>
                        </Tab>
                        <Tab onClick={() => {
                            this.containerRef.current?.firstElementChild?.scrollIntoView({behavior: 'smooth'});
                            this.setState((prevState) => ({
                                activeTab: 'interactions',
                                interactions: 'in',
                                currentPage: 1,
                                currentView: prevState.finalData.slice(0, prevState.itemsPerPage)
                            }))
                        }} style={{
                            border: this.state.activeTab === 'interactions' ? '1px solid #7E32CA' : '',
                            boxShadow: this.state.activeTab === 'interactions' ? '0px 0px 0px 4px #7E32CA1A' : ''
                        }}
                        ><p className="tab-title">Interactions</p>
                        </Tab>
                    </Filters>
                    <SearchResultPage>
                        <Overview>
                            See below the mutual agreement result for {" "}
                            <p className='search_term'>{this.state.searchTerm}</p>
                        </Overview>
                        <Container style={{marginBottom: "50px"}}>
                            <SyncLoader
                                css={override}
                                size={15}
                                margin={2}
                                color={"#7E32CA"}
                                loading={this.state.isLoading}
                            />
                            <Options>
                                {this.state.activeTab === 'interactions' ?
                                    <Pills>
                                        <Filter>
                                            <input type='radio' name='interactions' value='in' id='in'
                                                   checked={this.state.interactions === 'in'}
                                                   onChange={() => {
                                                       this.containerRef.current?.firstElementChild?.scrollIntoView({behavior: 'smooth'});
                                                       this.setState((prevState) => ({
                                                           interactions: "in",
                                                           currentPage: 1,
                                                           currentView: prevState.finalData.slice(0, prevState.itemsPerPage)
                                                       }))
                                                   }}
                                            />
                                            <span className='radio'></span>
                                            <label htmlFor='in'><p className="filter-title">In-degree Measure</p><p
                                                className="tab-value">{this.state.inDegree} results</p></label>
                                        </Filter>
                                        <Filter>
                                            <input type='radio' name='interactions' value='out' id='out'
                                                   checked={this.state.interactions === 'out'}
                                                   onChange={() => {
                                                       this.containerRef.current?.firstElementChild?.scrollIntoView({behavior: 'smooth'});
                                                       this.setState((prevState) => ({
                                                           interactions: "out",
                                                           currentPage: 1,
                                                           currentView: prevState.finalData.slice(0, prevState.itemsPerPage)

                                                   }))}}/>
                                            <span className='radio'></span>
                                            <label htmlFor='out'><p className="filter-title">Out-degree Measure</p><p
                                                className="tab-value">{this.state.outDegree} results</p>
                                            </label>
                                        </Filter>
                                    </Pills> :
                                    <FilterGroup>
                                        <Filter>
                                            <input
                                                disabled={this.state.result["1st"]?.length === undefined}
                                                type="checkbox" checked={this.state.filterBy1stDeg}
                                                onChange={(evt) => {
                                                    this.setState({
                                                        filterBy1stDeg: evt.target.checked,
                                                        filterBy2ndDeg: false,
                                                        filterBy3rdDeg: false,
                                                    })
                                                    if (evt.target.checked) {
                                                        this.handleFilter("1st")
                                                    } else {
                                                        this.handleFilter("all")
                                                    }
                                                }}
                                                id="first-degree" className="w-20 filter"/>
                                            <label htmlFor="first-degree"><p className="filter-title">1st Degree</p>
                                                {!this.state.isLoading ?
                                                    <p className="tab-value">{this.state.result["1st"]?.length || 0} results</p> : null}
                                            </label>
                                        </Filter>
                                        <Filter>
                                            <input disabled={this.state.result["2nd"]?.length === undefined}
                                                   type="checkbox"
                                                   checked={this.state.filterBy2ndDeg}
                                                   onChange={(evt) => {
                                                       this.setState({
                                                           filterBy2ndDeg: evt.target.checked,
                                                           filterBy1stDeg: false,
                                                           filterBy3rdDeg: false
                                                       })
                                                       if (evt.target.checked) {
                                                           this.handleFilter("2nd")
                                                       } else {
                                                           this.handleFilter("all")
                                                       }
                                                   }}
                                                   id="second-degree" className="w-20 filter"/>
                                            <label htmlFor="second-degree"><p className="filter-title">2nd
                                                Degree</p>
                                                {!this.state.isLoading ?
                                                    <p className="tab-value">{this.state.result["2nd"]?.length || 0} results</p> : null}
                                            </label>
                                        </Filter>
                                        <Filter>
                                            <input disabled={this.state.result["3rd"]?.length === undefined}
                                                   type="checkbox"
                                                   checked={this.state.filterBy3rdDeg}
                                                   onChange={(evt) => {
                                                       this.setState({
                                                           filterBy3rdDeg: evt.target.checked,
                                                           filterBy2ndDeg: false,
                                                           filterBy1stDeg: false
                                                       })
                                                       if (evt.target.checked) {
                                                           this.handleFilter("3rd")
                                                       } else {
                                                           this.handleFilter("all")
                                                       }
                                                   }}
                                                   id="third-degree" className="w-20 filter"/>
                                            <label htmlFor="third-degree"><p className="filter-title">3rd Degree</p>
                                                {!this.state.isLoading ?
                                                    <p className="tab-value">{this.state.result["3rd"]?.length || 0} results</p> : null}
                                            </label>
                                        </Filter>
                                    </FilterGroup>}
                                <Views>
                                    <div
                                        className={`active-tab ${this.state.view === "list" ? "tab-left" : "tab-right"}`}>
                                    </div>
                                    <img className={`tab-img ${this.state.view === "list" ? "active-tab-img" : ""}`}
                                         src={ListView} alt="list view" height={20}
                                         width={20}
                                         onClick={() => this.setState({view: "list"})}/>
                                    <img className={`tab-img ${this.state.view === "grid" ? "active-tab-img" : ""}`}
                                         src={GridView} alt="grid view" height={20}
                                         width={20}
                                         onClick={() => this.setState({view: "grid"})}/>
                                </Views>
                            </Options>
                            <div style={{
                                backgroundColor: '#000',
                                height: '1px',
                                opacity: '0.03',
                                width: '100%',
                                marginBottom: '27px'
                            }}></div>
                            {this.state.currentView && this.state.currentView.length > 0 && !this.state.isLoading && (
                                <div ref={this.containerRef} className="details-panel">
                                    <div className="tab-content" id="pills-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="pills-topcontacts"
                                            role="tabpanel"
                                            aria-labelledby="pills-home-tab"
                                        >
                                            <ul className={`result_cards ${this.state.view === "list" ? "list_cards" : "grid_cards"}`}>
                                                {this.state.currentView
                                                    .sort((a, b) => b.in - a.in)
                                                    .map((item, index) => (
                                                        <a style={{textDecoration: 'none'}}
                                                           href={`https://twitter.com/${item.username}`}
                                                           target='_blank'>
                                                            <li key={index}
                                                                className="flex align-items-center card-item mx-0">
                                                                <p className="handle_avatar">{this.initialName(item.name)}</p>
                                                                <div
                                                                    className={`details flex ${this.state.view === "list" ? "flex-row align-items-center justify-content-between" : "flex-column align-items-start"}`}>
                                                                    {this.state.view === "grid" ?
                                                                        <p className="user_name">{item.name.length > 18 ? item.name.slice(0, 18) + '...' : item.name}</p> :
                                                                        <p className="user_name">{item.name.length > 28 ? item.name.slice(0, 28) + '...' : item.name}</p>}
                                                                    <p className="user_handle">
                                                                        {item.username.length > 16 ? item.username.slice(0, 16) + '...' : item.username}
                                                                    </p>
                                                                    {
                                                                        this.state.interactions ?
                                                                            <p className="interactions">{this.state.interactions === "in" ? item.in : item.out}</p> : null}
                                                                </div>
                                                                <img src={Copy}
                                                                     alt="copy handle"
                                                                     height={46}
                                                                     width={46}
                                                                     onClick={() => this.copyHandle(item.username)}
                                                                />
                                                            </li>
                                                        </a>))}
                                            </ul>
                                            {this.state.filteredData.length !== this.state.currentView.length ?
                                                <button className="load__more"
                                                        onClick={this.loadMore}>Load More Results
                                                </button> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Container>
                    </SearchResultPage>
                </Main>
            </Container>
            <Modal isOpen={this.state.showModal}>
                <ModalContainer>
                    <svg
                        width="133"
                        height="136"
                        viewBox="0 0 133 136"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.06661 82.1324C2.94807 73.9992 2.45223 65.5284 3.60739 57.2036C4.76256 48.8787 7.54611 40.8629 11.7991 33.6138C16.0521 26.3648 21.6913 20.0244 28.3947 14.9546C35.0981 9.88491 42.7344 6.18516 50.8676 4.06661C59.0008 1.94807 67.4716 1.45223 75.7965 2.60739C84.1213 3.76256 92.1371 6.54611 99.3862 10.7991C106.635 15.0521 112.976 20.6913 118.045 27.3947C123.115 34.0981 126.815 41.7344 128.933 49.8676C131.052 58.0008 131.548 66.4716 130.393 74.7965C129.237 83.1213 126.454 91.1371 122.201 98.3862C117.948 105.635 112.309 111.976 105.605 117.045C98.9019 122.115 91.2656 125.815 83.1324 127.933C74.9992 130.052 66.5284 130.548 58.2035 129.393C49.8787 128.237 41.8629 125.454 34.6138 121.201C27.3648 116.948 21.0243 111.309 15.9546 104.605C10.8849 97.9019 7.18515 90.2656 5.06661 82.1324L5.06661 82.1324Z"
                            stroke="#7E32CA"
                            stroke-opacity="0.5"
                            stroke-width="4"
                        />
                        <path
                            opacity="0.1"
                            d="M53.072 128.681C43.7839 126.765 35.0376 122.809 27.4657 117.099C19.8938 111.389 13.6855 104.068 9.28988 95.6642C4.89421 87.2608 2.42104 77.9856 2.04921 68.5093C1.67738 59.033 3.4162 49.5925 7.1399 40.8706C10.8636 32.1486 16.4791 24.3632 23.5803 18.0774C30.6815 11.7917 39.0909 7.16259 48.2004 4.52502C57.3098 1.88745 66.8915 1.30731 76.2526 2.82656C85.6137 4.3458 94.5202 7.92646 102.328 13.3096"
                            stroke="#7E32CA"
                            stroke-opacity="0.5"
                            stroke-width="4"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M81.9048 23.8257L72.0426 24.8625L68.0088 33.9208L77.871 32.884L81.9048 23.8257Z"
                            fill="#5932D0"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M87.7325 31.8481L83.6976 40.9063L77.8691 32.8825L81.9029 23.8242L87.7314 31.8469L87.7325 31.8481Z"
                            fill="#B8A3F9"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M73.8392 41.9435L68.0107 33.9209L77.8718 32.884L83.7002 40.9079L73.8392 41.9435Z"
                            fill="#9376E9"
                        />
                        <path
                            opacity="0.493"
                            d="M126.36 66.1118C126.36 98.5532 100.061 124.852 67.6196 124.852C35.1782 124.852 8.87924 98.5532 8.87924 66.1118C8.87924 33.6704 35.1782 7.37143 67.6196 7.37143C100.061 7.37143 126.36 33.6704 126.36 66.1118Z"
                            fill="url(#paint0_linear)"
                            stroke="#B8A3F9"
                            stroke-width="0.742858"
                        />
                        <g opacity="0.961">
                            <g opacity="0.961" filter="url(#filter0_d)">
                                <path
                                    d="M55.6369 84.9198C66.8229 84.9198 75.891 75.8517 75.891 64.6657C75.891 53.4797 66.8229 44.4116 55.6369 44.4116C44.4509 44.4116 35.3828 53.4797 35.3828 64.6657C35.3828 75.8517 44.4509 84.9198 55.6369 84.9198Z"
                                    fill="black"
                                />
                            </g>
                            <path
                                opacity="0.961"
                                d="M55.6369 84.9198C66.8229 84.9198 75.891 75.8517 75.891 64.6657C75.891 53.4797 66.8229 44.4116 55.6369 44.4116C44.4509 44.4116 35.3828 53.4797 35.3828 64.6657C35.3828 75.8517 44.4509 84.9198 55.6369 84.9198Z"
                                fill="url(#paint1_linear)"
                            />
                        </g>
                        <g opacity="0.701">
                            <g opacity="0.961" filter="url(#filter1_d)">
                                <path
                                    d="M75.7814 104.489C86.9674 104.489 96.0355 95.4208 96.0355 84.2348C96.0355 73.0488 86.9674 63.9807 75.7814 63.9807C64.5954 63.9807 55.5273 73.0488 55.5273 84.2348C55.5273 95.4208 64.5954 104.489 75.7814 104.489Z"
                                    fill="black"
                                />
                            </g>
                            <path
                                opacity="0.961"
                                d="M75.7814 104.489C86.9674 104.489 96.0355 95.4208 96.0355 84.2348C96.0355 73.0488 86.9674 63.9807 75.7814 63.9807C64.5954 63.9807 55.5273 73.0488 55.5273 84.2348C55.5273 95.4208 64.5954 104.489 75.7814 104.489Z"
                                fill="url(#paint2_linear)"
                            />
                        </g>
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2 62.3643L5.2275 71.7406L14.962 73.6321L11.7345 64.257L2 62.3654V62.3643Z"
                            fill="#5932D0"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.50585 54.8806L18.2404 56.7722L11.7345 64.2557L2 62.3642L8.50585 54.8806Z"
                            fill="#B8A3F9"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.4689 66.1486L14.9619 73.6321L11.7344 64.257L18.2402 56.7734L21.4677 66.1486H21.4689Z"
                            fill="#9376E9"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M122.77 44.6226L117.44 49.9509L119.391 57.2311L124.721 51.9016L122.77 44.6226Z"
                            fill="#5932D0"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M130.05 46.5732L132 53.8523L124.721 51.9016L122.771 44.6226L130.05 46.5732Z"
                            fill="#B8A3F9"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M126.672 59.1813L119.392 57.2306L124.721 51.9011L132 53.8518L126.672 59.1813Z"
                            fill="#9376E9"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M43.7959 8.24585L40.3604 11.6814L41.6171 16.3749L45.0538 12.9393L43.7959 8.24585Z"
                            fill="#5932D0"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M48.4896 9.50375L49.7475 14.1972L45.0528 12.9393L43.7949 8.24585L48.4884 9.50375H48.4896Z"
                            fill="#B8A3F9"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M46.3107 17.6339L41.6172 16.376L45.0528 12.9392L49.7462 14.1971L46.3107 17.6339Z"
                            fill="#9376E9"
                        />
                        <defs>
                            <filter
                                id="filter0_d"
                                x="7.00915"
                                y="18.4024"
                                width="97.2555"
                                height="97.2555"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                />
                                <feOffset dy="2.36447"/>
                                <feGaussianBlur stdDeviation="14.1868"/>
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.34902 0 0 0 0 0.196078 0 0 0 0 0.815686 0 0 0 0.252972 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow"
                                    result="shape"
                                />
                            </filter>
                            <filter
                                id="filter1_d"
                                x="27.1537"
                                y="37.9715"
                                width="97.2555"
                                height="97.2555"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                            >
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                />
                                <feOffset dy="2.36447"/>
                                <feGaussianBlur stdDeviation="14.1868"/>
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.34902 0 0 0 0 0.196078 0 0 0 0 0.815686 0 0 0 0.252972 0"
                                />
                                <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow"
                                    result="shape"
                                />
                            </filter>
                            <linearGradient
                                id="paint0_linear"
                                x1="116.551"
                                y1="115.303"
                                x2="41.1931"
                                y2="-1.71544"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#C7BFDF"/>
                                <stop offset="0.5301" stop-color="#F2EFFF"/>
                                <stop offset="1" stop-color="#F1EDFF"/>
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear"
                                x1="64.5851"
                                y1="39.4534"
                                x2="35.3828"
                                y2="84.9198"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#F3F1F8"/>
                                <stop offset="1" stop-color="#5932D0"/>
                            </linearGradient>
                            <linearGradient
                                id="paint2_linear"
                                x1="84.7297"
                                y1="59.0225"
                                x2="55.5273"
                                y2="104.489"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#F3F1F8"/>
                                <stop offset="1" stop-color="#5932D0"/>
                            </linearGradient>
                        </defs>
                    </svg>

                    <AccountCreated>
                        Your search result is being processed!
                    </AccountCreated>

                    <VerifyP>
                        You will receive an email notification once it’s complete.
                    </VerifyP>

                    <ResendVerificationButton
                        onClick={(evt) => this.setState({redirectTo: "/"})}
                    >
                        Return to homepage
                    </ResendVerificationButton>
                </ModalContainer>
            </Modal>
            <AppFooter/>
        </main>)
            ;
    }
}
