import React, { useEffect } from "react";
import { createPortal } from "react-dom";

// 'modal-root' is a sibling to 'app-root'
const modalRoot = document.getElementById("modal-root");

function Modal({ isOpen, isSmall, children }) {
  // element to which the modal will be rendered
  const el = document.createElement("div");

  useEffect(() => {
    // append to root when the children of Modal are mounted
    modalRoot.appendChild(el);

    // do a cleanup
    return () => {
      modalRoot.removeChild(el);
    };
  }, [el]);

  return (
    isOpen &&
    createPortal(
      // child element
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "calc(100vh + 400px)",
          width: "100%",
          padding: "100px",
          backgroundColor: "rgba(0,0,0,0.6)",
          zIndex: "1"
        }}
      >
        <div className="popup_modal"
          style={{
            width: (isSmall ? "35%" : "50%"),
            background: "white",
            padding: "50px",
            textAlign: "center",
            margin: "0 auto",
            borderRadius: "5.42251px",
            position: "relative",
            zIndex: "5",
          }}
        >
          {children}
        </div>
      </div>,
      // target container
      el
    )
  );
}

export default Modal;