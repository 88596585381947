import React, { useState, useEffect } from "react";
import { HeaderWrapper } from "../../../Components/DashboardHeader/styles";
import { Container, PageContent } from "./styles";
import axios from "axios";
import API_URL from "../../../utils/api";
import {
  Flex,
  Text,
  Box,
  useToast,
  Heading,
  Image,
  Grid,
  Input,
  InputGroup,
  Button,
  InputRightElement,
  Icon,
} from "@chakra-ui/core";
import Store from "../../../utils/store";
import rejectedIcon from "./rejected.svg";
import acceptedIcon from "./accepted.svg";
import adminsIcon from "./admins.svg";
import blockedUsersIcon from "./blockedUsers.svg";
import searchesIcon from "./searches.svg";
import totalRequestIcon from "./totalRequest.svg";
import pendingIcon from "./pending.svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import FilterByPeriod from "../../../Components/FilterByPeriod";
import EmptyStateMessage from "../../../Components/Message";
import Pagination from "../../../Components/Pagination/index";
import { useHistory } from "react-router-dom";

let baseUrl = API_URL;

const OverviewPage = () => {
  const toast = useToast();
  const history = useHistory();
  const [graph, setgraph] = useState(undefined);

  // stats numbers
  const [totalRequest, settotalRequest] = useState(0);
  const [approvedRequest, setapprovedRequest] = useState(0);
  const [pendingRequest, setpendingRequest] = useState(0);
  const [rejectedRequest, setrejectedRequest] = useState(0);
  const [totalBlockedUsers, settotalBlockedUsers] = useState(0);
  const [totalSearch, settotalSearch] = useState(0);
  const [totalAdmin, settotalAdmin] = useState(0);

  const [paginationData, setpaginationData] = useState({});
  const [searchHistory, setsearchHistory] = useState([]);
  const [dateRange, setdateRange] = useState({ start: "", end: "" });

  const stats = [
    {
      name: "Total Request",
      icon: totalRequestIcon,
      total: totalRequest,
    },
    {
      name: "Accepted Request",
      icon: acceptedIcon,
      total: approvedRequest,
    },
    {
      name: "Pending Request",
      icon: pendingIcon,
      total: pendingRequest,
    },
    {
      name: "Rejected Request",
      icon: rejectedIcon,
      total: rejectedRequest,
    },
    {
      name: "Total Blocked",
      icon: blockedUsersIcon,
      total: totalBlockedUsers,
    },
    {
      name: "Total Search",
      icon: searchesIcon,
      total: totalSearch,
    },
    {
      name: "Total Admin",
      icon: adminsIcon,
      total: totalAdmin,
    },
  ];

  const saveDateRange = (payload) => {
    setdateRange(payload);
    console.log(payload);
    fetchHistoryByPeriod(payload);
  };

  const fetchStats = (payload) => {
    let userAuth = JSON.parse(Store.getAuthToken());
    axios
      .get(
        `${baseUrl}/admin/overview/stats?token=${userAuth.token.token}&from=${payload.start}&to=${payload.end}`
      )
      .then((response) => {
        let { data } = response;
        settotalRequest(data.requests);
        setapprovedRequest(data.approved);
        setpendingRequest(data.pending);
        setrejectedRequest(data.rejected);
        settotalAdmin(data.admins);
        settotalBlockedUsers(data.blocked);
        settotalSearch(data.searches);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchGraphs = (payload) => {
    let userAuth = JSON.parse(Store.getAuthToken());
    axios
      .get(
        `${baseUrl}/admin/overview/graph?token=${userAuth.token.token}&from=${payload.start}&to=${payload.end}`
      )
      .then((response) => {
        let { data } = response;
        setgraph(data.graph);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchHistoryBySearch = (payload) => {
    let userAuth = JSON.parse(Store.getAuthToken());
    axios
      .get(
        `${baseUrl}/admin/overview/history?token=${userAuth.token.token}&query=${payload}&limit=10`
      )
      .then((response) => {
        let { data } = response;
        setsearchHistory(data.data);
        setpaginationData({
          total: data.total,
          perPage: data.perPage,
          page: data.page,
          lastPage: data.lastPage,
        });
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchHistoryByPage = (payload) => {
    let userAuth = JSON.parse(Store.getAuthToken());
    axios
      .get(
        `${baseUrl}/admin/overview/history?token=${userAuth.token.token}&from=${dateRange.start}&to=${dateRange.end}&page=${payload}&limit=10`
      )
      .then((response) => {
        let { data } = response;
        setsearchHistory(data.data);
        setpaginationData({
          total: data.total,
          perPage: data.perPage,
          page: data.page,
          lastPage: data.lastPage,
        });
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchHistoryByPeriod = (payload) => {
    // setpaginationData({ ...paginationData, page: 1 });
    let userAuth = JSON.parse(Store.getAuthToken());
    axios
      .get(
        `${baseUrl}/admin/overview/history?token=${userAuth.token.token}&from=${payload.start}&to=${payload.end}&limit=10`
      )
      .then((response) => {
        let { data } = response;
        setsearchHistory(data.data);
        setpaginationData({
          total: data.total,
          perPage: data.perPage,
          page: data.page,
          lastPage: data.lastPage,
        });
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (Store.getAuthToken()) {
      fetchStats({ start: "", end: "" });
      fetchGraphs({ start: "", end: "" });
      fetchHistoryByPeriod({ start: "", end: "" });
    }
  }, []);

  // useEffect(() => {
  //   fetchHistoryByPeriod({ start: "", end: "" });
  // }, [searchInput]);

  // useEffect(() => {
  //   fetchHistoryByPeriod({ start: "", end: "" });
  // }, [paginationData.page]);

  return (
    <div>
      <HeaderWrapper>
        <Container>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex direction="column">
              <Flex alignItems="center">
                <Text
                  fontFamily="Montserrat"
                  fontWeight={500}
                  color="white"
                  fontSize={28}
                >
                  Overview
                </Text>
              </Flex>
              <Text color="rgba(249,249,249,0.7)" fontFamily="Montserrat">
                See breakdown of the dashboard
              </Text>
            </Flex>
          </Flex>
        </Container>
      </HeaderWrapper>
      <Container>
        <PageContent>
          <Flex justifyContent="flex-end" marginBottom={5} width="100%">
            <FilterByPeriod
              emitDateRange={fetchStats}
              wrappedRef={(instance) => {
                this.toggle = instance.toggle;
              }}
            />
          </Flex>
          {/* overview summary */}
          <Grid templateColumns="repeat(4, 1fr)" gap={2} width="100%">
            {stats.map((stat, index) => (
              <Box key={index} w="100%" bg="white" padding={10}>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom={4}
                >
                  <Text fontFamily="Montserrat">{stat.name}</Text>
                  <Image src={stat.icon} alt={stat.name} />
                </Flex>
                <Heading fontSize={52} fontFamily="Montserrat">
                  {stat.total}
                </Heading>
              </Box>
            ))}
          </Grid>

          {/* graph */}
          <Box
            background="white"
            width="100%"
            marginTop={20}
            borderRadius={5}
            border="1px solid rgba(130, 127, 152, 0.15)"
          >
            <Flex
              justifyContent="space-between"
              alignItems="center"
              paddingY={8}
              paddingX={8}
              borderBottom="1px solid rgba(130, 127, 152, 0.15)"
            >
              <Heading fontWeight={600} fontSize={16} fontFamily="Montserrat">
                Search Trend Overtime
              </Heading>
              <FilterByPeriod emitDateRange={fetchGraphs} />
            </Flex>
            <Box padding={8}>
              <LineChart width={1000} height={400} data={graph}>
                <CartesianGrid strokeDasharray="3" />
                <XAxis dataKey="date" />
                <YAxis dataKey="size" />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="size"
                  stroke="#8884d8"
                  strokeWidth={2}
                  activeDot={{ r: 0 }}
                />
              </LineChart>
            </Box>
          </Box>

          {/* history */}
          <Box
            background="white"
            width="100%"
            marginTop={20}
            borderRadius={5}
            border="1px solid rgba(130, 127, 152, 0.15)"
          >
            <Flex
              paddingY={5}
              paddingX={5}
              borderBottomWidth={1}
              borderStyle="solid"
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading fontSize={16} fontFamily="Montserrat">
                All Search History
              </Heading>
              <Flex>
                <InputGroup
                  width={220}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  background="transparent"
                  marginRight={10}
                >
                  <Input
                    onKeyUp={(e) => {
                      fetchHistoryBySearch(e.target.value);
                    }}
                    placeholder="Search for a user"
                    color="#333"
                    backgroundColor="transparent"
                    borderWidth={1.5}
                    paddingY={4}
                    paddingLeft="20px"
                    fontWeight={400}
                    fontFamily="Montserrat"
                  />
                  <InputRightElement
                    paddingRight="20px"
                    paddingY={4}
                    children={<Icon name="search" color="#DADADA" />}
                  />
                </InputGroup>
                <FilterByPeriod
                  emitDateRange={saveDateRange}
                  wrappedRef={(instance) => {
                    this.toggle = instance.toggle;
                  }}
                />
              </Flex>
            </Flex>
            {searchHistory.length > 0 ? (
              <Box className="table dispatchTable">
                <table>
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th style={{ border: 0 }}>Full Name</th>
                      <th style={{ border: 0 }}>Handle searched</th>
                      <th style={{ border: 0 }}>Date Searched</th>
                      <th style={{ border: 0 }}>Time</th>
                      <th style={{ border: 0 }}></th>
                    </tr>
                  </thead>

                  <tbody>
                    {searchHistory.map((user, index) => (
                      <tr className="tr" key={index}>
                        <td>{user.user}</td>
                        <td>{user.username}</td>
                        <td>{user.date}</td>
                        <td>{user.time}</td>
                        <td>
                          <Button
                            onClick={() =>
                              history.push({
                                pathname: `/dashboard/users/${user.user_id}`,
                                state: { userData: user },
                              })
                            }
                            borderColor="#7E32CA"
                            color="#7E32CA"
                            background="transparent"
                            fontFamily="Montserrat"
                            fontWeight={400}
                            _hover={{ background: "#7E32CA", color: "white" }}
                          >
                            Search history
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Box paddingX={5}>
                  <Pagination
                    currentPage={paginationData.page}
                    total={paginationData.total}
                    lastPage={paginationData.lastPage}
                    perPage={paginationData.perPage}
                    handleOnChange={(e) => {
                      fetchHistoryByPage(e);
                      // setpaginationData({ ...paginationData, page: e });
                      // console.log(paginationData);
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <EmptyStateMessage
                message="No search history"
                illustration="DocumentIllustration"
                subMessage=""
              />
            )}
          </Box>
        </PageContent>
      </Container>
    </div>
  );
};

export default OverviewPage;
