import React, { useState, useEffect } from "react"
import axios from "axios"

import API_URL from "../../../utils/api"
// import Store from '../../../utils/store'

import logo from "../../../assets/svg/logo.svg"
import {
  Flex,
  Text,
  Box,
  Image,
  Link,
  Button,
  Input,
  useToast,
} from "@chakra-ui/core"

import { Mask } from "./styles"

let baseUrl = API_URL

const ForgotPassword = () => {
  const toast = useToast()
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {}, [email])

  const handleForgotPassword = () => {
      setLoading(true)
    if (!email) {
      toast({
        title: "Important",
        description: "Please enter email.",
        status: "info",
        position: "top",
        duration: 9000,
        isClosable: true,
      })
      setLoading(false)
      return
    }
    axios
      .post(`${baseUrl}/password/forget`, { email })
      .then(result => {
        // let { data } = result
        setLoading(false)
        toast({
          title: "Important",
          description: `Password reset details successfully sent to ${email}.`,
          status: "success",
          position: "top",
          duration: 9000,
          isClosable: true,
        })
      })
      .catch(error => {
        if (error.response) {
          toast({
            title: "Error",
            description: "This email is not regsitered",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top",
          })
        }
        setLoading(false)
      })
  }

  return (
    <Box
      height='100vh'
      background='linear-gradient(192.05deg, #7e32ca 0%, #6008a3 100%)'
    >
      <Mask>
        <Box
          position='absolute'
          top='50%'
          left='50%'
          transform='translate(-50%, -50%)'
        >
          <Flex justifyContent='center'>
            <Link href='/'>
              <Image src={logo} alt='logo' />
            </Link>
          </Flex>
          <Box
            marginTop='40px'
            background='white'
            borderRadius='8px'
            width='450px'
            padding='46px'
          >
            <Box textAlign='center'>
              <Text
                fontWeight={600}
                color='#474747'
                fontSize='20px'
                fontFamily='Montserrat'
              >
                Forgot your password?
              </Text>

              <Text
                fontWeight={300}
                marginTop='10px'
                marginBottom='30px'
                fontFamily='Montserrat'
                fontSize='14px'
                color='rgba(51, 51, 51, 0.9)'
              >
                Please enter the email associated with your account to reset
                your password.
              </Text>
            </Box>
            <form
              onSubmit={e => {
                e.preventDefault()
                handleForgotPassword()
              }}
            >
              <Box
                borderColor='rgba(149, 152, 170, 0.5)'
                borderWidth='2px'
                padding='11px 20px'
                borderRadius='5px'
                marginBottom='25px'
              >
                <Text
                  fontFamily='Montserrat'
                  color='rgba(51,51,51,0.7)'
                  fontWeight={300}
                >
                  Email address
                </Text>
                <Input
                  background='transparent'
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  fontWeight={400}
                  placeholder='example@gmail.com'
                  variant='unstyled'
                  name='email'
                  fontFamily='Montserrat'
                />
              </Box>
              <Button
                type='submit'
                backgroundColor='#7E32CA'
                borderRadius='8px'
                width='100%'
                color='white'
                paddingY='28px'
                _hover='red'
                isLoading={loading}
                fontFamily='Montserrat'
              >
                Reset Password
              </Button>
            </form>
          </Box>
        </Box>
      </Mask>
    </Box>
  )
}

export default ForgotPassword
