import { Button } from "@chakra-ui/core";
import React from "react";
import styled from "styled-components";

const PrivacyDiv = styled.div`
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  text-align: justify;
`;
const Title = styled.h5`
  text-align: center;
  font-family: Graphik;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
`;
const SubTitle = styled.p`
  text-align: center;
  margin-bottom: 49px;
  font-family: Graphik;
  font-style: normal;
  font-weight: 200;
  font-size: 10px;
`;
const NoticeWrapper = styled.div``;
// const FlexColumn = styled.div`
//   display: flex;
//   flex-direction: column;
// `

const H4 = styled.h4`
  display: flex;
  flex-start: end;
  font-weight: bold;
  font-size: 10;
  margin-bottom: 10px;
`;
const H3 = styled.h3`
  display: flex;
  flex-start: end;
  font-weight: bold;
  font-size: 7;
  margin-bottom: 10px;
`;

const P = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 26px;
`;
const LiCircle = styled.li`
  list-style-type: circle;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const PrivacyNotice = (props) => (
  <React.Fragment>
    <PrivacyDiv>
      <Title>Privacy Notice</Title>
      <SubTitle>Update September 2020</SubTitle>

      <NoticeWrapper>
        <div>
          <H4>OverView</H4>
          <P>
            This privacy policy (hereby known as "the policy") will help you
            understand how we use and protect the data you provide to us when
            you visit and use the SNA Platform. We reserve the right to change
            this policy at any given time, of which you will be promptly
            updated. If you want to make sure that you are up to date with the
            latest changes, we advise you to frequently visit this page.
          </P>
        </div>

        <div>
          <H4>Consent and Information collection and Use</H4>
          <H3>Type of information collected</H3>
          <P>
            Below is a summary of how the SNA platform (hereby known as “The
            platform”) collects
          </P>
          <ol styled={{ margin: "0px", padding: "0px" }}>
            <LiCircle>* First and Last name</LiCircle>

            <LiCircle>* Email</LiCircle>

            <LiCircle>* Affiliated institution</LiCircle>

            <LiCircle>* Twitter user handle</LiCircle>

            <LiCircle>
              * Information required to be allowed to use the platform
            </LiCircle>
          </ol>
        </div>

        <div>
          <H3 style={{ marginTop: "12px" }}>
            How do we collect the information?
          </H3>
          <P>
            The information is collected when you fill the registration form
          </P>

          <H3>How do we use your data?</H3>
          <P>
            Data collected by this platform is used to enable the user to log in
            and perform social network analysis.{" "}
          </P>

          <H3>Platform use</H3>
          <P>
            Any user found to use the platform for ill intentions will
            automatically be reported to relevant authorities and user access
            revoked. The platform should only be used in times of emergency to
            establish the closest connections to a user. How long do we store
            the data? In line with CcHUB’s Privacy Policy, we store your data
            for 7 years. However, you have th right to have your information
            deleted at any time.
          </P>

          <H3>Third parties</H3>
          <P>
            We do not share your information with third-party organizations or
            individuals. We may however share your information with government
            or government agencies if we are required to do so by law or court
            order. User rights The user of this platform reserves the right at
            any point to: Access your data on the platform *Review, *correct,
            delete or restrict further processing of your data with us; Lodge a
            complaint with the National Information Technology Development
            Agency (NITDA) should you be dissatisfied with any area of our
            policy or how we use your data *any data reviewed and or corrected
            and found to be violating the platform’s intended use will be
            immediately deleted and the user’s access to the platform revoked.
          </P>

          <H3>Cookies</H3>
          <P>
            We use cookies on our website to understand the behavior of users to
            help us improve our services
          </P>

          <H3>Security and Data Breach Notification</H3>
          <P>
            We take the security of your information seriously and have invested
            in precautionary and safety measures to secure and protect your data
            with us – including encryption, data loss prevention mechanisms,
            2-factor authentication, a minimal access approach, among others.
            While we take care to do this, unfortunately, no security system is
            foolproof – including the channels through which you supply us your
            data and the actual systems we have put in place to safeguard them.
            You bear the responsibility of keeping your login information safe
            (including usernames, emails and passwords). In the event of a data
            breach, we will notify you within 3 days (or 72 hours) of discovery.
            If you feel at any time that your data may have been compromised
            (e.g, due to phone theft or hacking) please contact our Research
            Team at CcHUB{" "}
            <span style={{ fontWeight: "bold" }}>
              (research@cchubnigeria.com)
            </span>
          </P>
        </div>
      </NoticeWrapper>

      <FlexBetween>
        <div></div>
        <Button
          width="225px"
          background="#7E32CA"
          borderRadius="4px"
          color="white"
          size="lg"
          marginX="10px"
          onClick={() => props.close()}
          _hover={false}
        >
          Understood
        </Button>
      </FlexBetween>
    </PrivacyDiv>
  </React.Fragment>
);

export default PrivacyNotice;
