import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  height: 100vh;
  margin: 0 auto;
  margin-top: 104px;
  position: relative;

  @media (max-width: 529px) {
    width: 100% !important;
  }
`;
export const ContainerWrapper = styled.div`
  margin-top: 30px;
`;
export const Flex = styled.div`
  display: flex;
`;

export const FlexAlign = styled.div`
  display: flex;
`;

export const Width30 = styled.div`
  height: 200px;
  width: 30%;
  margin-right: 5%;
`;
export const Width70 = styled.div`
  width: 40%;
`;
export const ItemWrapper = styled.div``;

export const Ul = styled.ul``;
export const Li = styled.li`
  height: 75px;
  margin-bottom: 25px;
`;

export const MyDetails = styled.div`
  background: rgba(241, 237, 255, 0.3);
  border-radius: 10px 10px 0px 0px;
  height: inherit;
  padding: 24px 34px;
`;

export const AccountPreference = styled.div`
  height: inherit;
  padding: 24px 34px;
`;

export const DetailP = styled.p`
  font-family: Gotham;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #7e32ca;
`;

export const Div = styled.div``;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between !important;
  align-items: center;
`;

export const AccountPreferenceStyle = {
  height: "inherit",
  padding: "24px 34px",
};
