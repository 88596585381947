import styled from "styled-components";

export const Container = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  display: flex;
`;

export const PageContent = styled.div`
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const Card = styled.div`
  height: 250px;
  width: 48.5%;
  margin-bottom: 30px;
  background: rgba(237, 237, 237, 0.3);
  border: 1px solid rgba(8, 27, 56, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 30px;
`;
