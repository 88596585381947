import React from "react";
import { Box, Flex, Text, Heading } from "@chakra-ui/core";
import { Pagination } from "antd";
import "./styles.scss";

const PaginationComponent = ({
  currentPage,
  totalPage,
  total,
  lastPage,
  handleOnChange,
}) => {
  return (
    <Flex
      paddingY={5}
      justifyContent="space-between"
      alignItems="center"
      borderTop="1px solid #E7E9EF"
    >
      <Text>
        Showing {currentPage}-{lastPage} of {total} results
      </Text>
      <Box>
        <Pagination
          current={currentPage}
          showSizeChanger={false}
          defaultCurrent={1}
          total={total}
          onChange={handleOnChange}
        />
      </Box>
    </Flex>
  );
};

export default PaginationComponent;
