import React, { Component } from "react";
import {
  Label,
  Input,
  FormControl,
  FullFormControl,
  PasswordWraper,
  PasswordOrTextInput,
  EyeSVG,
  FormControlTextArea,
  Main,
  Flex,
  FlexItem,
  AuthContainer,
  AuthWrapper,
  SignUPAgreement,
  SignUpTitle,
  SubTitle,
  FormSection,
  GridTwo,
  HalfFormControl,
  TextBox,
  SignupButton,
  Already,
  FlexItemWithBg,
  ModalContainer,
  AccountCreated,
  VerifyP,
  ResendVerificationButton,
  ModalCheck,
} from "./styles";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import zxcvbn from "zxcvbn";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import API_URL from "../../../utils/api";
import Store from "../../../utils/store";
import Modal from "../../../Components/Modal/Modal";
import success from "../../../assets/svg/success.svg";
import Logo from "../../../assets/svg/logo-dark.svg";

let baseUrl = API_URL;

export default class Signup extends Component {
  state = {
    redirectTo: "",
    showModal: false,
    hidden: true,
    password: "",
    minStrength: 3,
    thresholdLength: 7,
    strength: 0,
  };

  componentDidMount() {
    if (Store.getAuthToken()) {
      this.setState({ redirectTo: "/search" });
    }
  }

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  toggleHidden = (evt) => this.setState({ hidden: !this.state.hidden });

  notifyError = (message) => toast.error(message);

  signUp = (evt) => {
    axios
      .post(`${baseUrl}/register`, this.state)
      .then((result) => {
        this.setState({ showModal: true });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data) {
            if (
              error.response.data.message &&
              error.response.data.message ===
                "unique validation failed on email"
            ) {
              this.notifyError("An account with this email already exists");
              return;
            }

            if (
              error.response.data.message &&
              error.response.data.message ===
                "min validation failed on password"
            ) {
              this.notifyError("Password should be 8 characters and above.");
              return;
            }

            this.notifyError(error.response.data.Error || "An Error occurred.");
          }
        }
      });
  };

  resendConfirmationEmail = (evt) => {
    axios
      .post(`${baseUrl}/resend?email=${this.state.email}`)
      .then((result) => {
        alert("Confirmation Email sent. Please check your email.");
        return;
      })
      .catch((error) => {
        error = error.response;
        console.error(error);
      });
  };

  fieldStateChanged = (field) => (state) =>
    this.setState({ [field]: state.errors.length === 0 });
  passwordChanged = this.fieldStateChanged("password");
  handlePasswordChange = (evt) => {
    // let passwordLength = evt.target.value.length;
    // let passwordScore = 0
    // if(passwordLength < 2) {
    //   passwordScore = 1
    // } else if (passwordLength > 2 && passwordLength < 4) {
    //   passwordScore = 2
    // } else if (passwordLength > 4 && passwordLength < 7) {
    //   passwordScore = 3
    // } else {
    //   passwordScore = 4
    // }
    this.setState({
      password: evt.target.value,
      strength: zxcvbn(evt.target.value).score,
    });
    console.dir(this.state);
  };

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    const {  strength } = this.state;
    const passwordLength = this.state.password.length;

    const strengthClass = [
      "strength-meter mt-2",
      passwordLength > 0 ? "visible" : "invisible",
    ]
      .join(" ")
      .trim();
    return (
      <Main onClick={() => this.setState({ showModal: false })}>
        <Flex>
          <FlexItem>
            <AuthContainer>
              <AuthWrapper>
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
                <SignUpTitle>Sign Up</SignUpTitle>
                <SubTitle style={{ marginBottom: "32px" }}>
                  Give us some information for us to grant you access to the
                  platform.
                </SubTitle>

                <FormSection>
                  <GridTwo>
                    <HalfFormControl>
                      <Label>First Name</Label>
                      <Input
                        type="text"
                        name="firstName"
                        onChange={this.handleChange}
                      />
                    </HalfFormControl>

                    <HalfFormControl>
                      <Label>Last Name</Label>
                      <Input
                        type="text"
                        name="lastName"
                        onChange={this.handleChange}
                      />
                    </HalfFormControl>
                  </GridTwo>

                  <FormControl>
                    <FullFormControl>
                      <Label>Email address</Label>
                      <Input
                        type="text"
                        name="email"
                        onChange={this.handleChange}
                      />
                    </FullFormControl>
                  </FormControl>

                  <FormControl>
                    <FullFormControl>
                      <Label>Password</Label>
                      <PasswordWraper>
                        <PasswordOrTextInput
                          type={this.state.hidden ? "password" : "text"}
                          name="password"
                          placeholder="Min 8 characters"
                          onChange={this.handlePasswordChange}
                        />

                        <EyeSVG
                          onClick={this.toggleHidden}
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.0002 0.5C14.4935 0.5 18.2319 3.73333 19.016 8C18.2327 12.2667 14.4935 15.5 10.0002 15.5C5.50688 15.5 1.76854 12.2667 0.984375 8C1.76771 3.73333 5.50688 0.5 10.0002 0.5ZM10.0002 13.8333C11.6998 13.833 13.3489 13.2557 14.6776 12.196C16.0063 11.1363 16.936 9.65689 17.3144 8C16.9346 6.34442 16.0043 4.86667 14.6757 3.80835C13.3471 2.75004 11.6988 2.17377 10.0002 2.17377C8.30162 2.17377 6.65328 2.75004 5.32469 3.80835C3.99609 4.86667 3.06585 6.34442 2.68604 8C3.06446 9.65689 3.9941 11.1363 5.32283 12.196C6.65155 13.2557 8.30065 13.833 10.0002 13.8333ZM10.0002 11.75C9.00565 11.75 8.05182 11.3549 7.34856 10.6516C6.6453 9.94839 6.25021 8.99456 6.25021 8C6.25021 7.00544 6.6453 6.05161 7.34856 5.34835C8.05182 4.64509 9.00565 4.25 10.0002 4.25C10.9948 4.25 11.9486 4.64509 12.6519 5.34835C13.3551 6.05161 13.7502 7.00544 13.7502 8C13.7502 8.99456 13.3551 9.94839 12.6519 10.6516C11.9486 11.3549 10.9948 11.75 10.0002 11.75ZM10.0002 10.0833C10.5527 10.0833 11.0826 9.86384 11.4733 9.47314C11.864 9.08244 12.0835 8.55253 12.0835 8C12.0835 7.44747 11.864 6.91756 11.4733 6.52686C11.0826 6.13616 10.5527 5.91667 10.0002 5.91667C9.44767 5.91667 8.91777 6.13616 8.52707 6.52686C8.13637 6.91756 7.91688 7.44747 7.91688 8C7.91688 8.55253 8.13637 9.08244 8.52707 9.47314C8.91777 9.86384 9.44767 10.0833 10.0002 10.0833Z"
                            fill="#737373"
                          />
                        </EyeSVG>
                      </PasswordWraper>
                      <div className={strengthClass}>
                        <div
                          className="strength-meter-fill"
                          data-strength={strength}
                        ></div>
                      </div>
                    </FullFormControl>
                  </FormControl>

                  <FormControl>
                    <FullFormControl>
                      <Label>Affliated Institution</Label>
                      <Input
                        type="text"
                        name="institution"
                        placeholder="where do you work?"
                        onChange={this.handleChange}
                      />
                    </FullFormControl>
                  </FormControl>

                  <FormControl>
                    <FormControlTextArea>
                      <TextBox
                        name="description"
                        placeholder="Tell us how you intend to use this data."
                        rows="12"
                        cols="30"
                        onChange={this.handleChange}
                      />
                    </FormControlTextArea>
                  </FormControl>

                  <SignUPAgreement>
                    By signing up you agree to our{" "}
                    <span className="bold">Terms of Service</span> and{" "}
                    <span className="bold">Privacy Policy.</span>
                  </SignUPAgreement>

                  <SignupButton onClick={this.signUp}>
                    Create account
                  </SignupButton>
                  <Already>
                    Already have an account?{" "}
                    <span
                      className="primary login-primary"
                      onClick={() =>
                        this.setState({ redirectTo: "/auth/login" })
                      }
                    >
                      Login here
                    </span>
                  </Already>
                </FormSection>
              </AuthWrapper>
            </AuthContainer>
          </FlexItem>
          <FlexItemWithBg className="signup-bg"></FlexItemWithBg>
        </Flex>

        <Modal isOpen={this.state.showModal}>
          <ModalContainer>
            <ModalCheck src={success} alt="" />

            <AccountCreated>Your account has been created!</AccountCreated>

            <VerifyP>
              Use the verification link emailed to{" "}
              <span className="bold">{this.state.email}</span> to confirm your
              account.
            </VerifyP>

            <ResendVerificationButton onClick={this.resendConfirmationEmail}>
              Resend verification
            </ResendVerificationButton>
          </ModalContainer>
        </Modal>
        <ToastContainer />
      </Main>
    );
  }
}
