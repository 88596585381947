import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Flex,
  Button,
  Image,
  Text,
  Input,
  Heading,
  useToast,
} from "@chakra-ui/core";
import { FlexItemWithBg } from "./styles";
import Logo from "../../../assets/svg/logo-dark.svg";
import EyeOpen from "../../../assets/svg/eye-open.svg";

import API_URL from "../../../utils/api";
import Store from "../../../utils/store";

let baseUrl = API_URL;

const Login = () => {
  let history = useHistory();
  let toast = useToast();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Store.getAuthToken()) {
      history.push("/search");
      return;
    }
  }, [history]);

  const handleLogin = () => {
    setLoading(true);

    axios
      .post(`${baseUrl}/login`, { email, password })
      .then((result) => {
        let { data } = result;
        Store.setAuthToken("user", JSON.stringify(data));
        let getToken = JSON.parse(Store.getAuthToken());

        let token = getToken.token.token;

        axios.get(`${baseUrl}/profile?token=${token}`).then((response) => {
          Store.setLoggedUserData("userData", JSON.stringify(response.data));
          history.push("/search");
          setLoading(false);
        });
      })
      .catch((error) => {
        if (error.response) {
          toast({
            title: "Error",
            description: "Incorrect log in detail",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
          setLoading(false);
          return;
        }
      });
  };

  return (
    <Flex height="100vh" background="white">
      <Box width="55%" paddingX={20}>
        <Box width="500px" height="100%" paddingTop={10}>
          <Link to="/">
            <Image src={Logo} alt="logo" />
          </Link>
          <Heading marginTop={24} color="#333333" fontFamily="Graphik">
            Login to your account.
          </Heading>
          <Text
            fontFamily="Graphik"
            marginTop={2}
            color="#4E5457"
            fontSize="14px"
            fontWeight={300}
          >
            Welcome Back.
          </Text>

          <Box marginTop={50}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              {/* email */}
              <Box
                marginBottom={8}
                borderColor="#C4C4C4"
                borderStyle="solid"
                borderWidth="1px"
                borderRadius="4px"
                paddingY={3}
                paddingX={5}
              >
                <Text color="#3C4859" fontFamily="Graphik" fontWeight={300}>
                  Email
                </Text>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  variant="unstyled"
                  fontFamily="Graphik"
                  fontWeight={300}
                />
              </Box>
              {/* password */}
              <Flex
                justifyContent="space-between"
                marginBottom={8}
                borderColor="#C4C4C4"
                borderStyle="solid"
                borderWidth="1px"
                borderRadius="4px"
                paddingY={3}
                paddingX={5}
              >
                <Box width="90%">
                  <Text color="#3C4859" fontFamily="Graphik" fontWeight={300}>
                    Password
                  </Text>
                  <Input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Min 8 characters"
                    type={showPassword ? "text" : "password"}
                    variant="unstyled"
                    fontFamily="Graphik"
                    fontWeight={300}
                  />
                </Box>
                <Image
                  onClick={() => setShowPassword(!showPassword)}
                  src={EyeOpen}
                />
              </Flex>

              <Box marginBottom={8}>
                <Link to="/forgot-password">
                  <Text color="#7E32CA" textDecoration="underline">
                    I don’t remember my password?
                  </Text>
                </Link>
              </Box>

              <Button
                type="submit"
                size="lg"
                width="100%"
                background="#7E32CA"
                color="white"
                paddingY={8}
                marginBottom={8}
                isLoading={loading}
              >
                Login
              </Button>
              <Flex justifyContent="center" textAlign="center">
                <Text
                  color="#5A6163"
                  marginRight={1}
                  fontFamily="Graphik"
                  fontWeight="300"
                >
                  Don’t have an account?
                </Text>
                <Link to="/auth/register">
                  <Text color="#7E32CA" fontWeight="500">
                    Signup here
                  </Text>
                </Link>
              </Flex>
            </form>
          </Box>
        </Box>
      </Box>
      <FlexItemWithBg className="signup-bg"></FlexItemWithBg>
    </Flex>
  );
};

export default Login;
