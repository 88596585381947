import React, { useState } from "react";
import axios from "axios";
import API_URL from "../../../utils/api";
import EyeOpen from "../../../assets/svg/eye-open.svg";
import {
  Button,
  Flex,
  Text,
  Input,
  Box,
  Heading,
  Image,
  useToast,
} from "@chakra-ui/core";
import Store from "../../../utils/store";

let baseUrl = API_URL;

const Password = () => {
  const toast = useToast();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);

  // password visibility
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const togglePasswordVisibility = (type) => {
    if (type === "oldPassword") {
      setShowOldPassword(!showOldPassword);
    } else {
      setShowNewPassword(!showNewPassword);
    }
  };
  const handleUpdatePassword = () => {
    setLoading(true);
    if (!oldPassword || !newPassword) {
      toast({
        title: "Complete the form",
        description: "Please complete the form",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
    } else {
      if (Store.getAuthToken()) {
        let userAuth = JSON.parse(Store.getAuthToken());
        axios
          .put(`${baseUrl}/password/change?token=${userAuth.token.token}`, {
            oldPassword,
            newPassword,
          })
          .then((response) => {
            const { data } = response.data;
            toast({
              title: "Password Updated",
              description: response.data.Success,
              status: "success",
              duration: 4000,
              isClosable: true,
              position: "top",
            });
            setOldPassword("");
            setNewPassword("");
            setLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              toast({
                title: "Error",
                description: error.response.data,
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
              });
              setOldPassword("");
              setNewPassword("");
              setLoading(false);
            }
          });
      }
    }
  };

  return (
    <Box background="white" borderRadius="5px" paddingBottom={10}>
      <Box paddingY={5} paddingX={10} borderBottomWidth={1} borderStyle="solid">
        <Heading fontFamily="Montserrat" fontSize={18}>
          Change Password
        </Heading>
      </Box>
      <Flex padding={10} justifyContent="space-between">
        <Box width="48%">
          <Heading
            fontSize={18}
            color="rgba(59, 59, 59, 0.9)"
            fontFamily="Montserrat"
          >
            Password
          </Heading>
          <Text color="rgba(59, 59, 59, 0.8)" fontFamily="Montserrat">
            Change your password to a new one
          </Text>
        </Box>
        <Box width="55%">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom={5}
            borderWidth={1.5}
            borderRadius={3}
            p={3}
            borderColor="rgba(149, 152, 170, 0.4)"
          >
            <Box>
              <Text color="rgba(51, 51, 51, 0.7)" fontFamily="Montserrat">
                Enter Old Password
              </Text>
              <Input
                width="80%"
                type={showOldPassword ? "text" : "password"}
                variant="unstyled"
                placeholder="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                fontFamily="Montserrat"
                fontWeight={400}
              />
            </Box>
            <Image
              onClick={() => togglePasswordVisibility("oldPassword")}
              src={EyeOpen}
            />
          </Flex>

          <Flex
            justifyContent="space-between"
            alignItems="center"
            borderWidth={1.5}
            borderRadius={3}
            p={3}
            borderColor="rgba(149, 152, 170, 0.4)"
          >
            <Box>
              <Text color="rgba(51, 51, 51, 0.7)" fontFamily="Montserrat">
                Enter New Password
              </Text>

              <Input
                width="80%"
                type={showNewPassword ? "text" : "password"}
                variant="unstyled"
                placeholder="password"
                value={newPassword}
                fontFamily="Montserrat"
                onChange={(e) => setNewPassword(e.target.value)}
                fontWeight={400}
              />
            </Box>
            <Image
              onClick={() => togglePasswordVisibility("newPassword")}
              src={EyeOpen}
            />
          </Flex>
        </Box>
      </Flex>
      <Flex paddingX={10} justifyContent="space-between" alignItems="center">
        <Button color="#7E32CA" variant="unstyled" fontFamily="Montserrat">
          Cancel
        </Button>
        <Button
          onClick={() => handleUpdatePassword()}
          size="lg"
          color="white"
          background="#7E32CA"
          isLoading={loading}
          fontFamily="Montserrat"
        >
          Change Password
        </Button>
      </Flex>
    </Box>
  );
};

export default Password;
