import React, { useState, useEffect } from "react";
import EyeOpen from "../../../assets/svg/eye-open.svg";
import axios from "axios";
import { useHistory } from "react-router-dom";

import API_URL from "../../../utils/api";
import Store from "../../../utils/store";

import logo from "../../../assets/svg/logo.svg";
import {
  Flex,
  Text,
  Box,
  Image,
  Link,
  Button,
  Checkbox,
  Input,
  useToast,
} from "@chakra-ui/core";

import { Mask } from "./styles";

let baseUrl = API_URL;

const AdminLogin = () => {
  const toast = useToast();
  const [hidden, setHidden] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {}, [email, hidden, password]);

  const handleLogin = () => {
    if (!email || !password) {
      toast({
        title: "Important",
        description: "Please enter email and password.",
        status: "info",
        position: "top",
        duration: 4000,
        isClosable: true,
      })
    } else {
      setLoading(true)
      axios
        .post(`${baseUrl}/login`, { email, password })
        .then(result => {
          let { data } = result
          Store.setAuthToken("user", JSON.stringify(data))

          let getToken = JSON.parse(Store.getAuthToken())
          let token = getToken.token.token

          axios.get(`${baseUrl}/profile?token=${token}`).then(response => {
            Store.setLoggedUserData("userData", JSON.stringify(response.data))
            history.push("/dashboard/request")
            setLoading(false)
          })
        })
        .catch(error => {
          setPassword("")
          if (error.response) {
            toast({
              title: "Error",
              description: "Incorrect log in detail",
              status: "error",
              duration: 4000,
              isClosable: true,
              position: "top",
            })
          }
          setLoading(false)
        })
    }
  };

  return (
    <Box
      height="100vh"
      background="linear-gradient(192.05deg, #7e32ca 0%, #6008a3 100%)"
    >
      <Mask>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Flex justifyContent="center">
            <Link href="/">
              <Image src={logo} alt="logo" />
            </Link>
          </Flex>
          <Box
            marginTop="40px"
            background="white"
            borderRadius="8px"
            width="450px"
            padding="46px"
          >
            <Box textAlign="center">
              <Text
                fontWeight={600}
                color="#474747"
                fontSize="20px"
                marginBottom="30px"
                fontFamily="Montserrat"
              >
                Welcome Back
              </Text>
            </Box>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleLogin();
              }}
            >
              <Box
                borderColor="rgba(149, 152, 170, 0.5)"
                borderWidth="2px"
                padding="11px 20px"
                borderRadius="5px"
                marginBottom="25px"
              >
                <Text
                  fontFamily="Montserrat"
                  color="rgba(51,51,51,0.7)"
                  fontWeight={300}
                >
                  Email address
                </Text>
                <Input
                  background="transparent"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  fontWeight={400}
                  placeholder="example@gmail.com"
                  variant="unstyled"
                  name="email"
                  fontFamily="Montserrat"
                />
              </Box>

              <Flex
                borderColor="rgba(149, 152, 170, 0.5)"
                borderWidth="2px"
                padding="11px 20px"
                borderRadius="5px"
                marginBottom="25px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Text
                    fontFamily="Montserrat"
                    color="rgba(51,51,51,0.7)"
                    fontWeight={300}
                  >
                    Password
                  </Text>
                  <Input
                    background="transparent"
                    fontWeight={400}
                    value={password}
                    variant="unstyled"
                    pr="4.5rem"
                    type={hidden ? "password" : "text"}
                    placeholder="Min 8 characters"
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    fontFamily="Montserrat"
                  />
                </Box>

                <Button
                  variant="unstyled"
                  size="sm"
                  onClick={() => setHidden(!hidden)}
                >
                  <img src={EyeOpen} alt="" />
                </Button>
              </Flex>
              <Flex marginBottom="25px" justifyContent="space-between">
                <Checkbox variantColor="purple">
                  <Text
                    color="#707070"
                    fontWeight={500}
                    fontFamily="Montserrat"
                    fontSize="14px"
                  >
                    Keep me Logged in
                  </Text>
                </Checkbox>
                <Link href="/admin/forgot-password">
                  <Text
                    color="#7E32CA"
                    fontWeight={500}
                    fontSize="14px"
                    fontFamily="Montserrat"
                  >
                    Forgot Password?
                  </Text>
                </Link>
              </Flex>
              <Button
                type="submit"
                backgroundColor="#7E32CA"
                borderRadius="8px"
                width="100%"
                color="white"
                paddingY="28px"
                _hover="red"
                isLoading={loading}
                fontFamily="Montserrat"
              >
                Login
              </Button>
            </form>
          </Box>
        </Box>
      </Mask>
    </Box>
  );
};

export default AdminLogin;
