import React, { Component } from "react";
import {
  FormControl,
  FormSection,
  GridTwo,
  HalfFormControl,
  Label,
  Input,
  FullFormControl,
  SignupButton,
} from "./styles";

import { ToastContainer } from "react-toastify";
// import "../../Views/Auth/Login/node_modules/react-toastify/dist/ReactToastify.css";
import axios from "axios";
import API_URL from "../../../utils/api";
import Store from "../../../utils/store";

const baseUrl = API_URL;

export default class PersonalDetail extends Component {
  state = {
    profile: {},
    firstName: "",
    lastName: "",
    email: "",
    institution: "",
  };

  static getDerivedStateFromProps(props, state) {
    if (props.profile !== state.profile) {
      const { firstName, lastName, email, institution } = props.profile;
      return { firstName, lastName, email, institution, profile: props.profile }
    }
    return null;
  }

  handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };

  updateDetails = (e) => {
    if (Store.getAuthToken()) {
      let userData = JSON.parse(Store.getAuthToken());
      let token = userData.token.token;

      axios
        .put(`${baseUrl}/profile?token=${token}`, this.state)
        .then((result) => {
          let { data } = result;
          console.dir(data);
          window.location.reload();
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  };
  render() {
    return (
      <main>
        <h2 className="password-title">Personal Details</h2>
        <p className="password-sub" style={{marginBottom:'10px'}}>Change your account details</p>

        <FormSection>
          <GridTwo>
            <HalfFormControl>
              <Label>First Name</Label>
              <Input
                onChange={this.handleChange}
                type="text"
                name="firstName"
                value={this.state.firstName || ''}
              />
            </HalfFormControl>

            <HalfFormControl>
              <Label>Last Name</Label>
              <Input
                onChange={this.handleChange}
                type="text"
                name="lastName"
                value={this.state.lastName || ''}
              />
            </HalfFormControl>
          </GridTwo>

          <FormControl>
            <FullFormControl>
              <Label>Email address</Label>
              <Input
                onChange={this.handleChange}
                type="text"
                name="email"
                value={this.state.email || ''}
              />
            </FullFormControl>
          </FormControl>

          <FormControl>
            <FullFormControl>
              <Label>Affliated Institution</Label>
              <Input
                onChange={this.handleChange}
                type="text"
                name="institution"
                placeholder="where do you work?"
                value={this.state.institution || ''}
              />
            </FullFormControl>
          </FormControl>

          <SignupButton onClick={this.updateDetails}>Save</SignupButton>
        </FormSection>
        <ToastContainer />
      </main>
    );
  }
}
