import React, { useState, useEffect } from "react"
import EyeOpen from "../../../assets/svg/eye-open.svg"
import axios from "axios"
import { useHistory, useLocation } from "react-router-dom"

import API_URL from "../../../utils/api"
// import Store from "../../../utils/store"

import logo from "../../../assets/svg/logo.svg"
import {
  Flex,
  Text,
  Box,
  Image,
  Link,
  Button,
  Input,
  useToast,
} from "@chakra-ui/core"

import { useQuery } from "../../../hooks"

import { Mask } from "./styles"

let baseUrl = API_URL

const ResetPassword = () => {
  const query = useQuery()
  const location = useLocation()
  const toast = useToast()

  console.log(location.pathname.includes('admin'));

  const [confirmationCode, setConfirmationCode] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  let history = useHistory()

  useEffect(() => {
    const queryToken = query.get("token")
    setToken(queryToken)
    return () => {
      return
    }
  }, [query, token])

  const handleSetNewPassword = () => {
    if (newPassword !== confirmNewPassword) {
      toast({
        title: "Important",
        description: "Password and confirm password do not match.",
        status: "info",
        position: "top",
        duration: 2500,
        isClosable: true,
      })
      return
    }
    if (!newPassword || !confirmNewPassword || !confirmationCode) {
      toast({
        title: "Important",
        description: "Please enter all fields.",
        status: "info",
        position: "top",
        duration: 9000,
        isClosable: true,
      })
    } else {
      setLoading(true)
      axios
        .put(`${baseUrl}/password/reset`, {
          confirmation_code: confirmationCode,
          token: token,
          newPassword: newPassword,
          confirmPassword: confirmNewPassword,
        })
        .then(result => {
          //   let { data } = result
          toast({
            title: "Success",
            description: "Password reset successful.",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          })
          setLoading(false)
          if(location.pathname.includes('admin')) history.push("/admin/login");
          else history.push("/auth/login")
          
        })
        .catch(error => {
          setLoading(false)
          if (error.response) {
            toast({
              title: "Error",
              description: "Something went wrong",
              status: "error",
              duration: 4000,
              isClosable: true,
              position: "top",
            })
          }
        })
    }
  }

  return (
    <Box
      height='100vh'
      background='linear-gradient(192.05deg, #7e32ca 0%, #6008a3 100%)'
    >
      <Mask>
        <Box
          position='absolute'
          top='50%'
          left='50%'
          transform='translate(-50%, -50%)'
        >
          <Flex justifyContent='center'>
            <Link href='/'>
              <Image src={logo} alt='logo' />
            </Link>
          </Flex>
          <Box
            marginTop='40px'
            background='white'
            borderRadius='8px'
            width='450px'
            padding='46px'
          >
            <Box textAlign='center'>
              <Text
                fontWeight={600}
                color='#474747'
                fontSize='20px'
                fontFamily='Montserrat'
              >
                {"Set a new password"}
              </Text>

              <Text
                fontWeight={300}
                marginTop='10px'
                marginBottom='30px'
                fontFamily='Montserrat'
                fontSize='14px'
                color='rgba(51, 51, 51, 0.9)'
              >
                To set a new password, enter the Confirmation code we sent to
                your email.
              </Text>
            </Box>

            <form
              onSubmit={e => {
                e.preventDefault()
                handleSetNewPassword()
              }}
            >
              <Box
                borderColor='rgba(149, 152, 170, 0.5)'
                borderWidth='2px'
                padding='11px 20px'
                borderRadius='5px'
                marginBottom='25px'
              >
                <Text
                  fontFamily='Montserrat'
                  color='rgba(51,51,51,0.7)'
                  fontWeight={300}
                >
                  Confirmation Code
                </Text>
                <Input
                  background='transparent'
                  onChange={e => setConfirmationCode(e.target.value)}
                  value={confirmationCode}
                  fontWeight={400}
                  placeholder='123456'
                  variant='unstyled'
                  name='confirmation Code'
                  fontFamily='Montserrat'
                />
              </Box>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                borderColor='rgba(149, 152, 170, 0.5)'
                borderWidth='2px'
                padding='11px 20px'
                borderRadius='5px'
                marginBottom='25px'
              >
                <Box>
                  <Text
                    fontFamily='Montserrat'
                    color='rgba(51,51,51,0.7)'
                    fontWeight={300}
                  >
                    New Password
                  </Text>
                  <Input
                    type={showPassword ? "text" : "password"}
                    background='transparent'
                    onChange={e => setNewPassword(e.target.value)}
                    value={newPassword}
                    fontWeight={400}
                    placeholder='Enter new password'
                    variant='unstyled'
                    name='new password'
                    fontFamily='Montserrat'
                  />
                </Box>
                <Image
                  onClick={() => setShowPassword(!showPassword)}
                  src={EyeOpen}
                />
              </Box>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                borderColor='rgba(149, 152, 170, 0.5)'
                borderWidth='2px'
                padding='11px 20px'
                borderRadius='5px'
                marginBottom='25px'
              >
                <Box>
                  <Text
                    fontFamily='Montserrat'
                    color='rgba(51,51,51,0.7)'
                    fontWeight={300}
                  >
                    Confirm New Password
                  </Text>
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    background='transparent'
                    onChange={e => setConfirmNewPassword(e.target.value)}
                    value={confirmNewPassword}
                    fontWeight={400}
                    placeholder='Confirm new password'
                    variant='unstyled'
                    name='confirm new password'
                    fontFamily='Montserrat'
                  />
                </Box>
                <Image
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  src={EyeOpen}
                />
              </Box>
              <Button
                type='submit'
                backgroundColor='#7E32CA'
                borderRadius='8px'
                width='100%'
                color='white'
                paddingY='28px'
                _hover='red'
                isLoading={loading}
                fontFamily='Montserrat'
              >
                Continue
              </Button>
            </form>
          </Box>
        </Box>
      </Mask>
    </Box>
  )
}

export default ResetPassword
