import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Container = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  //margin-bottom: 0px;
  display: flex;
`

export const PageContent = styled.div`
  width: 100%;
  margin: 50px auto;
`

export const MenuItem = styled(NavLink)`
  color: #827f98;
  padding: 18px 40px;
  border-right: 3px solid transparent;

  &:hover {
    text-decoration: none;
    color: rgba(24, 19, 26, 0.9);
  }

  &.${(props) => props.activeClassName} {
    color: red;
    background: rgba(126, 50, 202, 0.04);
    color: rgba(24, 19, 26, 0.9);
    border-right: 3px solid #7e32ca;
    font-weight: 400;
  }
`
