import React, { Component } from "react";
import axios from "axios";
import { Button } from "./styles";
import Modal from "../../../Components/Modal/Modal";
import API_URL from "../../../utils/api";
import Store from "../../../utils/store";
import ChangePassword from "../../Auth/ChangePassword";

const baseUrl = API_URL;

export default class Preference extends Component {
  state = {
    selected: false,
    showResetPasswordModal: false,
    oldPassword: "",
    newPassword: "",
    repeatPassword: "",
    passwordError: "",
  };

  showPasswordModal = (evt) => {
    this.setState({ showResetPasswordModal: true });
    console.log("CLicked...");
  };

  handleChage = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changePassword = (e) => {
    if (Store.getAuthToken()) {
      let userData = JSON.parse(Store.getAuthToken());
      let token = userData.token.token;

      if (this.state.repeatPassword !== this.state.newPassword) {
        this.setState({ passwordError: "Password doesn't match" });
      }
      axios
        .put(`${baseUrl}/password/change?token=${token}`, this.state)
        .then((result) => {
          let { data } = result;
          console.dir(data);
          window.location.reload();
        })
        .catch((error) => {
          console.error(error.response);
        });
    }
  };
  render() {
    return (
      <main style={{ width: "80%"}}>
        <h2 className="password-title">Password</h2>
        <p className="password-sub">Change your account details</p>

        <Button onClick={this.showPasswordModal} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <svg
            style={{ marginRight: "17.5px" }}
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 6.66666H14.6667C14.8877 6.66666 15.0996 6.75446 15.2559 6.91074C15.4122 7.06702 15.5 7.27898 15.5 7.49999V17.5C15.5 17.721 15.4122 17.933 15.2559 18.0893C15.0996 18.2455 14.8877 18.3333 14.6667 18.3333H1.33333C1.11232 18.3333 0.900358 18.2455 0.744078 18.0893C0.587797 17.933 0.5 17.721 0.5 17.5V7.49999C0.5 7.27898 0.587797 7.06702 0.744078 6.91074C0.900358 6.75446 1.11232 6.66666 1.33333 6.66666H3V5.83333C3 4.50725 3.52678 3.23548 4.46447 2.29779C5.40215 1.36011 6.67392 0.833328 8 0.833328C9.32608 0.833328 10.5979 1.36011 11.5355 2.29779C12.4732 3.23548 13 4.50725 13 5.83333V6.66666ZM2.16667 8.33333V16.6667H13.8333V8.33333H2.16667ZM7.16667 11.6667H8.83333V13.3333H7.16667V11.6667ZM3.83333 11.6667H5.5V13.3333H3.83333V11.6667ZM10.5 11.6667H12.1667V13.3333H10.5V11.6667ZM11.3333 6.66666V5.83333C11.3333 4.94927 10.9821 4.10143 10.357 3.47631C9.7319 2.85118 8.88406 2.49999 8 2.49999C7.11594 2.49999 6.2681 2.85118 5.64298 3.47631C5.01786 4.10143 4.66667 4.94927 4.66667 5.83333V6.66666H11.3333Z"
              fill="white"
            />
          </svg>
          <span>Change Password</span>
        </Button>

        <div className="data-access">
          <h2>Data Access</h2>
          <p>SNA uses the following data to personalize your experience</p>
          <div className={"formWrapper flex justify-between align-center "}>
            <p>Enable search history</p>
            {this.state.selected ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10" cy="10" r="10" fill="#276DF7" />
                <circle
                  cx="10"
                  cy="10"
                  r="7.5"
                  fill="#276DF7"
                  stroke="white"
                  stroke-width="3"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  opacity="0.4"
                  cx="10"
                  cy="10"
                  r="9.25"
                  fill="#FCF9F9"
                  stroke="#827F98"
                  stroke-width="1.5"
                />
              </svg>
            )}
          </div>
        </div>

        <div className="data-mgt">
          <h2>Data Management</h2>
          <p>You can delete your personal data from our systems</p>
          <Button>
            <svg
              style={{ marginRight: "17.5px" }}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.667 4.83332V15.6667C15.667 16.1087 15.4914 16.5326 15.1788 16.8452C14.8663 17.1577 14.4424 17.3333 14.0003 17.3333H4.00033C3.5583 17.3333 3.13437 17.1577 2.82181 16.8452C2.50925 16.5326 2.33366 16.1087 2.33366 15.6667V4.83332H0.666992V3.16666H17.3337V4.83332H15.667ZM4.00033 4.83332V15.6667H14.0003V4.83332H4.00033ZM8.16699 6.49999H9.83366V8.16666H8.16699V6.49999ZM8.16699 8.99999H9.83366V10.6667H8.16699V8.99999ZM8.16699 11.5H9.83366V13.1667H8.16699V11.5ZM4.83366 0.666656H13.167V2.33332H4.83366V0.666656Z"
                fill="white"
              />
            </svg>

            <span>Remove My Data</span>
          </Button>
        </div>

        <Modal isOpen={this.state.showResetPasswordModal} isSmall={true}>
          <ChangePassword />
        </Modal>
      </main>
    );
  }
}
