import React, { useState, useEffect } from "react";
import { HeaderWrapper } from "../../../Components/DashboardHeader/styles";
import { Container, PageContent, Card } from "./styles";
import axios from "axios";
import API_URL from "../../../utils/api";
import { Button, Flex, Text, Badge, Box, useToast } from "@chakra-ui/core";
import Email from "../../../assets/svg/email.svg";
import Organisation from "../../../assets/svg/organisation.svg";
import ButtonChecked from "../../../assets/svg/button-checked.svg";
import Store from "../../../utils/store";
import EmptyStateMessage from "../../../Components/Message";

let baseUrl = API_URL;

const RequestPage = () => {
  const [requests, setRequests] = useState([]);
  const toast = useToast();

  useEffect(() => {
    getRequests();
  }, []);

  const getRequests = () => {
    if (Store.getAuthToken()) {
      let userAuth = JSON.parse(Store.getAuthToken());
      axios
        .get(`${baseUrl}/admin/requests?token=${userAuth.token.token}`)
        .then((response) => {
          let { data } = response;
          setRequests(data);
        });
    }
  };

  const handleApprove = (id) => {
    let userAuth = JSON.parse(Store.getAuthToken());
    axios
      .put(`${baseUrl}/admin/approve/${id}?token=${userAuth.token.token}`)
      .then((response) => {
        let { data } = response;
        if ((data.success = "User approved")) {
          toast({
            position: "top",
            isClosable: true,
            status: "success",
            title: "Request Approval",
            description: "User Approved",
            duration: 4000,
          });
        }
        getRequests();
      });
  };

  const handleDecline = (id) => {
    let userAuth = JSON.parse(Store.getAuthToken());
    axios
      .put(`${baseUrl}/admin/decline/${id}?token=${userAuth.token.token}`)
      .then((response) => {
        let { data } = response;

        if ((data.success = "User declined")) {
          toast({
            position: "top",
            isClosable: true,
            status: "error",
            title: "Request Approval",
            description: "User Declined",
            duration: 4000,
          });
        }
        getRequests();
      });
  };

  return (
    <div>
      <HeaderWrapper>
        <Container>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Flex direction="column">
              <Flex alignItems="center">
                <Text
                  fontFamily="Montserrat"
                  fontWeight={500}
                  color="white"
                  fontSize={28}
                >
                  All Request
                </Text>
                <Badge
                  marginLeft={3}
                  backgroundColor="rgba(255, 255, 255, 0.15)"
                  color="white"
                  paddingY={1}
                  borderRadius={30}
                  paddingX={3}
                >
                  {requests.length}
                </Badge>
              </Flex>
              <Text color="rgba(249,249,249,0.7)" fontFamily="Montserrat">
                Approve or decline all received applications
              </Text>
            </Flex>
            {requests.length > 0 && (
              <Button size="lg" color="#7E32CA" bg="white">
                <Text marginRight={2}>Approve All</Text>
                <img src={ButtonChecked} alt="" />
              </Button>
            )}
          </Flex>
        </Container>
      </HeaderWrapper>
      <Container>
        <PageContent>
          {requests.length === 0 && (
            <EmptyStateMessage
              message="No Available Request"
              illustration="DocumentIllustration"
            />
          )}
          {requests.map((request, index) => {
            return (
              <Card key={index}>
                <Flex h="full" justify="space-between" direction="column">
                  <Flex direction="column" backgound="rgba(237, 237, 237, 0.3)">
                    <Text fontWeight="800" fontFamily="Montserrat">
                      {request.firstName} {request.lastName}
                    </Text>
                    <Flex marginTop={2}>
                      <Flex alignItems="center" marginRight={5}>
                        <img src={Email} alt="email" />
                        <Text
                          fontFamily="Montserrat"
                          marginLeft={1}
                          fontWeight="500"
                          color="rgba(51, 51, 51, 0.7)"
                        >
                          {request.email}
                        </Text>
                      </Flex>
                      <Flex alignItems="center">
                        <img src={Organisation} alt="email" />
                        <Text
                          marginLeft={1}
                          fontWeight="500"
                          color="#4F4F4F"
                          fontFamily="Montserrat"
                        >
                          {request.institution}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Text fontWeight="500">{request.description}</Text>
                  <Flex justifyContent="space-between">
                    <Button
                      onClick={() => handleDecline(request.id)}
                      variant="link"
                      color="#F42C1F"
                      fontFamily="Montserrat"
                    >
                      Decline
                    </Button>
                    <Button
                      onClick={() => handleApprove(request.id)}
                      backgroundColor="#45B412"
                      color="white"
                      size="sm"
                      fontFamily="Montserrat"
                    >
                      Approve
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            );
          })}
        </PageContent>
      </Container>
    </div>
  );
};

export default RequestPage;
