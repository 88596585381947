import styled from "styled-components";

export const Button = styled.button`
  cursor: pointer !important;
  margin-top: 10px;
  text-decoration: none !important;
  border-color: black !important;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica Neue, sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-width: 1px !important;
  border-style: solid !important;
  outline: none !important;
  padding: 14px 24px  !important;
  -webkit-transition: box-shadow 0.2s ease, -webkit-transform 0.1s ease,
    transform 0.1s ease !important;
  -moz-transition: box-shadow 0.2s ease, transform 0.1s ease !important;
  transition: box-shadow 0.2s ease, -ms-transform 0.1s ease,
    -webkit-transform 0.1s ease, transform 0.1s ease !important;
  border: none !important;
  background: -webkit-linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  background: -moz-linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  background: linear-gradient(
    to right,
    #7e32ca 0%,
    #7e32ca 50%,
    #7e32ca 100%
  ) !important;
  color: #ffffff !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 230px !important;
  height: 65px;
  border-radius: 4px;
  font-family: Gotham;
  font-size: 14px;
  color: #ffffff;
  margin-right: 26px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.7px solid #7e32ca;
  margin-bottom: 65px;
`;

export const AuthContainer = styled.div`
  width: 80%;
  margin: 0 auto;
`;
export const AuthWrapper = styled.div`
  margin-top: 87.65px;
`;
export const SignUpTitle = styled.h3`
  font-family: Gotham;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 46px;
  color: #333333;
`;

export const SubTitle = styled.p`
  font-family: Gotham;
  font-style: normal;
  font-weight: 100;
  font-size: 17px;
  line-height: 20px;
  color: #4e5457;
  margin-top: 5px;
`;

export const FormSection = styled.div``;
export const FormControl = styled.div`
  margin-bottom: 25px;
`;

export const FormControlTextArea = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  height: 140px;
`;
export const HalfFormControl = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  height: 65px;

  @media (max-width: 529px) {
    margin-bottom: 18px;
  }
`;

export const FullFormControl = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  height: 65px;
`;
export const Label = styled.label`
  margin: 10px 0 0 15px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 20px;
  color: #3c4859;
`;
export const Input = styled.input`
  height: inherit;
  border: none;
  outline: 0;
  box-shadow: 0;
  padding: 0;
  margin: 0 0 0 15px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 29px;
`;

export const GridTwo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 25px;

  @media (max-width: 529px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
`;

export const TextBox = styled.textarea`
  height: 140px;
  outline: none;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 18px 15px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 29px;
`;

export const SignupButton = styled.button`
  width: 100%;
  height: 65px;
  background: #7e32ca;
  box-shadow: 0px 2px 6px rgba(196, 196, 196, 0.3);
  border-radius: 4px;
  border: none;
  outline: none;
  font-family: Gotham;
  font-weight: bold;
  font-size: 16px;
  line-height: 143.16%;
  color: #fefefe;
`;
export const Already = styled.p`
  font-family: Graphik;
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #5a6163;
  margin-bottom: 50px;
  margin-top: 31px;

  @media (max-width: 529px) {
    margin-top: 10px;
  }
`;

export const SignUPAgreement = styled.p`
  margin-bottom: 32px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 29px;
`;

export const ModalContainer = styled.div`
  width: 100%;
`;

export const AccountCreated = styled.p`
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #0d0e1e;
`;
export const VerifyP = styled.p`
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 29px;

  color: #637381;
  margin-top: 60px;

  @media (max-width: 529px) {
    font-size: 13px;
    text-align: center;
  }
`;
export const ResendVerificationButton = styled.button`
  height: 45px;
  background: #7e32ca;
  box-shadow: 0px 2px 6px rgba(196, 196, 196, 0.3);
  border-radius: 4px;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 143.16%;
  color: #fefefe;
  padding: 10px 13px;
  margin-top: 60px;
  margin-bottom: 60px;
`;

export const PasswordWraper = styled.div`
  width: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const PasswordOrTextInput = styled.input`
  position: relative;
  height: inherit;
  border: none;
  outline: 0;
  box-shadow: 0;
  padding: 0;
  font-family: Gotham;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 29px;
  width: 100%;
`;

export const EyeSVG = styled.svg``;
