import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  width: 100%;
  background-image: url('/assets/images/login-mask.png');
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: rgba(96, 8, 163, 1);
  background-blend-mode: screen;
  background-size: cover;
  height: 130px;
  display: flex;
  align-items: center;
  margin: 80px auto;
  margin-bottom: 0px;
`

export const HeaderWrapperInnerContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  display: flex;
`
